import styled from 'styled-components';
import { font } from '../../style-utils/text';

export const Column = styled.div<{
  isCentered?: boolean;
  flex?: number;
  paddingLeft?: number;
  fitContent?: boolean;
  overflow?: string;
}>`
  display: flex;
  flex-direction: column;
  ${({ flex }) => (flex ? `flex: ${flex};` : `flex: 1;`)}

  ${({ fitContent }) =>
    fitContent
      ? `height: fit-content;`
      : `
      height: 100%;
    `}
    
    overflow: auto;
  min-height: 0;
  ${({ isCentered }) =>
    isCentered &&
    `
      align-items: center;
    `}
  ${({ paddingLeft }) =>
    paddingLeft &&
    `
      padding-left: ${paddingLeft}px;
    `}
     ${({ overflow }) =>
    overflow &&
    `
      overflow: ${overflow};
    `}
`;

export const Row = styled.div<{
  isCentered?: boolean;
  isEnd?: boolean;
  maxHeight?: number;
  minHeight?: number;
  width?: number;
  overflow?: string;
}>`
  display: flex;
  flex: 1;
  ${({ isCentered }) =>
    isCentered &&
    `
      align-items: center;
      justify-content: center;
    `}
  ${({ isEnd }) =>
    isEnd &&
    `
      align-items: flex-end;
      justify-content: flex-end;
    `}
  
    ${({ maxHeight }) =>
    maxHeight &&
    `
      max-height: ${maxHeight}px;
    `}
  
    ${({ minHeight }) =>
    minHeight &&
    `
      min-height: ${minHeight}px;
    `}
  
    ${({ width }) =>
    width &&
    `
      width: ${width}%;
    `}
     ${({ overflow }) =>
    overflow &&
    `
      overflow: ${overflow}px;
    `}
`;

export const Button = styled.button<{
  backgroundColor: string;
  textColor: string;
}>`
  margin-right: 16px;
  margin-left: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
  height: 27px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};
  font-family: ${font.family};
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-weight: 700;
  border-radius: 4px;
  border: 2px solid var(--Secondary-600, #0061a0);
`;
