import React, { useEffect, useState, useRef } from 'react';
import {
  EmployeeAttachButton,
  EmployeeDocumentsNewDocumentsMain,
  EmployeeDetailDocumentTable,
  EmployeeDetailDocumentTableHeader,
  EmployeeDetailDocumentTableHeaders,
  EmployeeDetailHeader,
  EmployeeDetailStyledLabel,
  EmployeeDetailsWrapper,
  EmployeeDocumentsAttachFileContainer,
  EmployeeDocumentsCommentContainer,
  EmployeeDocumentsNewDocuments,
  EmployeeDocumentsTextArea,
} from './employee.styled';
import { EllipsisText, Text, font } from '../../../style-utils/text';
import { colors } from '../../../style-utils/colors';
import { PlusIcon } from '../../icons/plus-icon-small';
import { EmployeeDocumentsTypes } from './employee';
import { FileIcon } from '../../icons/file-icon';
import { NewEmployeeDataTypes } from './add-employee';
import {
  AddEmployeeStyledButton,
  CustomUploadLabel,
  EducationListWrapper,
  HiddenUploadInput,
} from './add-employee.styled';
import {
  // JobBoardOverlay,
  StyledAddedEmail,
} from '../job-board/job-board.styled';
import { DeleteButton, WrapperDiv } from '../jobs/jobs.styled';
import axios, { AxiosError } from 'axios';
import { apiRoute } from '../../../constants/api-constants';
import MessagePopup from '../../messages/message-popup/message-popup';

interface EmployeeDocumentsTabProps {
  // eslint-disable-next-line
  company: any;
  employee: NewEmployeeDataTypes;
  employeeDocuments: EmployeeDocumentsTypes[];
  employeeDocumentsFromDB: () => void;
}

export const EmployeeDocumentsTab: React.FC<EmployeeDocumentsTabProps> = ({
  employeeDocuments,
  employee,
  company,
  employeeDocumentsFromDB,
}) => {
  const [toggleAttachFile, setToggleAttachFile] = useState<boolean>(false);
  const [commentInputValue, setCommentInputValue] = useState<string>('');
  const [newDocuments, setNewDocuments] = useState<File[]>([]);
  const [popUp, setPopUp] = useState<{
    text: string;
    type: 'success' | 'error';
  } | null>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const closePopUp = () => {
    setToggleAttachFile(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        closePopUp();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closePopUp]);

  const handleToggleAttachFile = (state: boolean) => {
    setToggleAttachFile(state);
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommentInputValue(e.target.value);
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target instanceof HTMLInputElement && e.target.files) {
      const files = e.target.files;
      setNewDocuments([...newDocuments, ...files]);
    }
  };

  const handleRemoveDocument = (documentToRemove: File) => {
    setNewDocuments((prevFiles) =>
      prevFiles.filter((file) => file.name !== documentToRemove.name)
    );
  };

  const handleSubmitNewDocuments = async () => {
    console.log('documents submitted');
    try {
      const formData = new FormData();
      // const files = [];

      if (commentInputValue) {
        formData.append('comment', commentInputValue);
      }

      if (newDocuments) {
        newDocuments.forEach((file) => {
          formData.append('documents', file);
        });
      }

      formData.append('employee_id', employee.id);
      if (company.entityType === 'employee') {
        formData.append('company_id', company.company_id);
      } else {
        formData.append('company_id', company.id);
      }

      const response = await axios.post(
        `${apiRoute}/add-new-documents`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );

      if (response.status === 200) {
        console.log(
          'New documents have been successfully added:',
          response.data
        );
        employeeDocumentsFromDB();
        setPopUp({
          text: 'New documents have been successfully added!',
          type: 'success',
        });
      } else {
        console.error('Unexpected response status:', response.status);
        setPopUp({
          text: 'Unexpected error',
          type: 'error',
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        console.error('Axios error:', axiosError.message);
        if (axiosError.response) {
          console.error('Response error:', axiosError.response.data);
        } else if (axiosError.request) {
          console.error('Request error:', axiosError.request);
        }
      } else {
        console.error('Non-Axios error:', error);
      }
    } finally {
      handleToggleAttachFile(false);
      setNewDocuments([]);
      setCommentInputValue('');
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (popUp) {
      timer = setTimeout(() => {
        setPopUp(null);
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [popUp]);

  return (
    <>
      {/* {toggleAttachFile && <JobBoardOverlay />} */}
      {toggleAttachFile && (
        <EmployeeDocumentsNewDocumentsMain onClick={() => closePopUp}>
          <EmployeeDocumentsNewDocuments ref={wrapperRef}>
            <Text
              fontFamily={font.family}
              margin="0 0 16px 0"
              color={colors.base_black}
              fontSize={24}
              fontWeight={700}
              lineHeight="28.8px"
            >
              Attach new documents
            </Text>
            <EmployeeDocumentsCommentContainer>
              <EmployeeDetailStyledLabel htmlFor="comment">
                Comment (optional)
              </EmployeeDetailStyledLabel>
              <EmployeeDocumentsTextArea
                name="comment"
                value={commentInputValue}
                id="comment"
                onChange={handleCommentChange}
              />
            </EmployeeDocumentsCommentContainer>
            <EmployeeDocumentsAttachFileContainer>
              <Text
                fontFamily={font.family}
                margin="0 0 16px 0"
                color={colors.base_black}
                fontSize={18}
                fontWeight={700}
              >
                Attach files here
              </Text>
              <CustomUploadLabel htmlFor="documents">
                {'Attach File (pdf, png, jpg)'}
              </CustomUploadLabel>
              <HiddenUploadInput
                type="file"
                name="documents"
                id="documents"
                accept="application/pdf, image/*"
                onChange={handleFileUpload}
                multiple
              />
              {newDocuments.length !== 0 && (
                <EducationListWrapper>
                  {newDocuments.map((document, index) => (
                    <StyledAddedEmail key={index}>
                      <Text
                        fontFamily={font.family}
                        color={colors.base_black}
                        margin="0"
                      >
                        {document.name}
                      </Text>
                      <DeleteButton
                        onClick={() => handleRemoveDocument(document)}
                      >
                        &#10006;
                      </DeleteButton>
                    </StyledAddedEmail>
                  ))}
                </EducationListWrapper>
              )}
            </EmployeeDocumentsAttachFileContainer>
            <WrapperDiv
              height="80px"
              alignItems="flex-end"
              justifyContent="flex-end"
              gap="8px"
            >
              <AddEmployeeStyledButton
                bgColor={colors.primary_400}
                borderRadius="4px"
                padding="8px 40px"
                color={colors.base_white}
                type="button"
                onClick={() => {
                  handleToggleAttachFile(false);
                  setNewDocuments([]);
                  setCommentInputValue('');
                }}
              >
                Cancel
              </AddEmployeeStyledButton>
              <AddEmployeeStyledButton
                bgColor={colors.primary_600}
                borderRadius="4px"
                padding="8px 40px"
                color={colors.base_white}
                type="button"
                onClick={handleSubmitNewDocuments}
              >
                Add
              </AddEmployeeStyledButton>
            </WrapperDiv>
          </EmployeeDocumentsNewDocuments>
        </EmployeeDocumentsNewDocumentsMain>
      )}
      <EmployeeDetailsWrapper>
        {employeeDocuments.length === 0 && (
          <EmployeeDetailHeader>
            <Text
              fontFamily={font.family}
              margin="0"
              color={colors.base_black}
              fontSize={24}
              fontWeight={700}
              lineHeight="28.8px"
            >
              No documents attached
            </Text>
            <EmployeeAttachButton onClick={() => handleToggleAttachFile(true)}>
              <PlusIcon />
              Attach file
            </EmployeeAttachButton>
          </EmployeeDetailHeader>
        )}
        {employeeDocuments.length > 0 && (
          <>
            <EmployeeDetailHeader mb="24px">
              <Text
                fontFamily={font.family}
                margin="0"
                color={colors.base_black}
                fontSize={24}
                fontWeight={700}
                lineHeight="28.8px"
              >
                Documents
              </Text>
              <EmployeeAttachButton
                onClick={() => handleToggleAttachFile(true)}
              >
                <PlusIcon />
                Attach file
              </EmployeeAttachButton>
            </EmployeeDetailHeader>
            <EmployeeDetailDocumentTable>
              <EmployeeDetailDocumentTableHeaders>
                <EmployeeDetailDocumentTableHeader
                  bgColor={colors.primary_300}
                  padding="8px 16px"
                  width="120px"
                >
                  <Text
                    fontFamily={font.family}
                    margin="0"
                    color={colors.base_white}
                    fontSize={16}
                    fontWeight={700}
                    lineHeight="19.2px"
                    align="center"
                  >
                    Upload Date
                  </Text>
                </EmployeeDetailDocumentTableHeader>
                <EmployeeDetailDocumentTableHeader
                  bgColor={colors.primary_500}
                  padding="8px 16px"
                  width="120px"
                >
                  <Text
                    fontFamily={font.family}
                    margin="0"
                    color={colors.base_white}
                    fontSize={16}
                    fontWeight={700}
                    lineHeight="19.2px"
                    align="center"
                  >
                    ID
                  </Text>
                </EmployeeDetailDocumentTableHeader>
                <EmployeeDetailDocumentTableHeader
                  bgColor={colors.primary_500}
                  padding="8px 16px"
                  fillWidth
                >
                  <Text
                    fontFamily={font.family}
                    margin="0"
                    color={colors.base_white}
                    fontSize={16}
                    fontWeight={700}
                    lineHeight="19.2px"
                    align="center"
                  >
                    Document name
                  </Text>
                </EmployeeDetailDocumentTableHeader>
                <EmployeeDetailDocumentTableHeader
                  bgColor={colors.primary_500}
                  padding="8px 16px"
                  fillWidth
                >
                  <Text
                    fontFamily={font.family}
                    margin="0"
                    color={colors.base_white}
                    fontSize={16}
                    fontWeight={700}
                    lineHeight="19.2px"
                    align="center"
                  >
                    File
                  </Text>
                </EmployeeDetailDocumentTableHeader>
                <EmployeeDetailDocumentTableHeader
                  bgColor={colors.primary_500}
                  padding="8px 16px"
                  fillWidth
                >
                  <Text
                    fontFamily={font.family}
                    margin="0"
                    color={colors.base_white}
                    fontSize={16}
                    fontWeight={700}
                    lineHeight="19.2px"
                    align="center"
                  >
                    Comment
                  </Text>
                </EmployeeDetailDocumentTableHeader>
              </EmployeeDetailDocumentTableHeaders>
              {employeeDocuments.map((document) => {
                return (
                  <EmployeeDetailDocumentTableHeaders key={document.id}>
                    <EmployeeDetailDocumentTableHeader
                      bgColor={colors.neutral_50}
                      padding="8px 16px"
                      width="120px"
                    >
                      <Text
                        fontFamily={font.family}
                        margin="0"
                        color={colors.base_black}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight="16.8px"
                        align="center"
                      >
                        {document.upload_date}
                      </Text>
                    </EmployeeDetailDocumentTableHeader>
                    <EmployeeDetailDocumentTableHeader
                      bgColor={colors.neutral_50}
                      padding="8px 16px"
                      width="120px"
                    >
                      <EllipsisText
                        fontFamily={font.family}
                        margin="0"
                        color={colors.base_black}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight="16.8px"
                        align="center"
                      >
                        {document.id}
                      </EllipsisText>
                    </EmployeeDetailDocumentTableHeader>
                    <EmployeeDetailDocumentTableHeader
                      bgColor={colors.neutral_50}
                      padding="8px 16px"
                      fillWidth
                    >
                      <Text
                        fontFamily={font.family}
                        margin="0"
                        color={colors.base_black}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight="16.8px"
                        align="center"
                      >
                        {document.document_name}
                      </Text>
                    </EmployeeDetailDocumentTableHeader>
                    <EmployeeDetailDocumentTableHeader
                      bgColor={colors.neutral_50}
                      padding="8px 16px"
                      gap="8px"
                      fillWidth
                      cursor={'true'}
                      onClick={() => {
                        window.open(
                          document.storage_location as string,
                          '_blank'
                        );
                      }}
                    >
                      <FileIcon />
                      <EllipsisText
                        fontFamily={font.family}
                        margin="0"
                        color={colors.base_black}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight="16.8px"
                        align="center"
                      >
                        {document.document_name}
                      </EllipsisText>
                    </EmployeeDetailDocumentTableHeader>
                    <EmployeeDetailDocumentTableHeader
                      bgColor={colors.neutral_50}
                      padding="8px 16px"
                      fillWidth
                    >
                      <Text
                        fontFamily={font.family}
                        margin="0"
                        color={colors.base_black}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight="16.8px"
                        align="center"
                      >
                        {document.comments && document.comments}
                        {!document.comments && 'No comments.'}
                      </Text>
                    </EmployeeDetailDocumentTableHeader>
                  </EmployeeDetailDocumentTableHeaders>
                );
              })}
            </EmployeeDetailDocumentTable>
          </>
        )}
        {popUp && <MessagePopup text={popUp.text} type={popUp.type} />}
      </EmployeeDetailsWrapper>
    </>
  );
};
