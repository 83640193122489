import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { apiRoute } from '../../constants/api-constants';

interface PayPalComponentProps {
  amount: number;
}

export const PayPalSubscription: React.FC<PayPalComponentProps> = ({
  amount,
}) => {
  const [loading, setLoading] = useState(false);
  const [clientId, setClientId] = useState('');

  useEffect(() => {
    const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;
    if (clientId) {
      setClientId(clientId);
    } else {
      console.error(
        'PayPal Client ID is missing. Please set it in the environment variables.'
      );
    }
  }, []);

  const createOrder = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${apiRoute}/paypal-pay`, {
        amount,
      });
      setLoading(false);

      return response.data.forwardLink;
    } catch (error) {
      console.error('There was an error creating the payment!', error);
      setLoading(false);
    }
  };

  if (!clientId) {
    return <p>PayPal Client ID is missing. Please check your configuration.</p>;
  }

  return (
    <PayPalScriptProvider options={{ clientId }}>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <PayPalButtons
          createOrder={async () => {
            const orderID = await createOrder();

            return orderID;
          }}
          onApprove={async (data, actions) => {
            if (actions && actions.order) {
              const details = await actions.order.capture();
              const payerName = details.payer?.name?.given_name || 'Customer';
              alert('Transaction completed by ' + payerName);
            }
          }}
        />
      )}
    </PayPalScriptProvider>
  );
};
