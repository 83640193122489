import React, { useState, useRef, useEffect } from 'react';
import {
  Wrapper,
  ModalContent,
  StyledTitle,
  TitleAndInputWrapper,
  StyledDateInput,
  StyledInput,
  StyledSelect,
  StyledLabel,
  FeatureInputWrapper,
  FeatureItem,
  FeaturesWrapper,
  AddButton,
  EditDeleteIcons,
  DiscountWrapper,
  PriceWrapper,
  ButtonWrapper,
  BackButton,
  CreateButton,
} from './modal.styled';
import { apiRoute } from '../../../../../constants/api-constants';
import axios from 'axios';
import { SubscriptionPlan } from '../interface';
import { DeleteIcon } from '../../../../icons/delete-icon';
import { EditIcon } from '../../../../icons/edit-icon';

interface ModalProps {
  closeModal: () => void;
  loadPlans: () => void;
  selectedPlan?: SubscriptionPlan | null;
}

const Modal: React.FC<ModalProps> = ({
  closeModal,
  selectedPlan,
  loadPlans,
}) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  const [title, setTitle] = useState(selectedPlan?.title || '');
  const [features, setFeatures] = useState<string[]>(
    selectedPlan?.features || []
  );
  const [currentFeature, setCurrentFeature] = useState('');
  const [editingFeatureIndex, setEditingFeatureIndex] = useState<number | null>(
    null
  );
  const [discount, setDiscount] = useState({
    from: selectedPlan?.discount?.from || '',
    to: selectedPlan?.discount?.to || '',
    price: selectedPlan?.discount?.price || '',
    currency: selectedPlan?.discount?.currency || 'USD',
  });
  const [price, setPrice] = useState({
    amount: selectedPlan?.price?.amount || '',
    currency: selectedPlan?.price?.currency || 'USD',
  });

  const handleClickOutside = (e: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleFeatureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentFeature(e.target.value);
  };

  const addFeature = () => {
    if (currentFeature) {
      if (editingFeatureIndex !== null) {
        const updatedFeatures = [...features];
        updatedFeatures[editingFeatureIndex] = currentFeature;
        setFeatures(updatedFeatures);
        setEditingFeatureIndex(null);
      } else {
        setFeatures([...features, currentFeature]);
      }
      setCurrentFeature('');
    }
  };

  const editFeature = (index: number) => {
    setCurrentFeature(features[index]);
    setEditingFeatureIndex(index);
  };

  const removeFeature = (index: number) => {
    setFeatures(features.filter((_, i) => i !== index));
  };

  const handleDiscountChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setDiscount({ ...discount, [e.target.name]: e.target.value });
  };

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrice({ ...price, amount: e.target.value });
  };

  const handlePriceCurrencyChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setPrice({ ...price, currency: e.target.value });
  };

  const handleSubmit = async () => {
    console.log('Form submission: Subscription plan data:', {
      title,
      features,
      discount,
      price,
    });

    if (!title) {
      alert('Title is required');

      return;
    }
    if (features.length === 0) {
      alert('At least one feature is required');

      return;
    }
    if (!discount.from || !discount.to) {
      alert('Discount promotion dates (from and to) are required');

      return;
    }
    if (!discount.price) {
      alert('Discount price is required');

      return;
    }
    if (!price.amount) {
      alert('Price amount is required');

      return;
    }

    if (discount.currency === '%' && parseFloat(discount.price) > 99) {
      alert('Discount cannot exceed 99%');

      return;
    }

    const planData = {
      title,
      features,
      discount,
      price,
    };

    try {
      if (selectedPlan) {
        console.log(
          'Sending PUT request to update subscription plan',
          planData
        );
        const response = await axios.put(
          `${apiRoute}/subscription-plan/update/${selectedPlan.id}`,
          planData
        );
        console.log('Subscription plan updated successfully:', response.data);
        alert('Subscription plan updated successfully.');
      } else {
        console.log(
          'Sending POST request to create subscription plan',
          planData
        );
        const response = await axios.post(
          `${apiRoute}/subscription-plan/create`,
          planData
        );
        console.log('Subscription plan created successfully:', response.data);
        alert('Subscription plan created successfully.');
      }
      closeModal();
      loadPlans();
      // eslint-disable-next-line
    } catch (error: any) {
      console.error(
        'Error saving subscription plan:',
        error.response || error.message
      );
      alert('Error saving subscription plan. Please try again.');
    }
  };

  return (
    <Wrapper>
      <ModalContent ref={modalRef}>
        <StyledTitle>
          {selectedPlan
            ? 'Edit Subscription Plan'
            : 'Create New Subscription Plan'}
        </StyledTitle>

        {/* Title Input */}
        <TitleAndInputWrapper>
          <StyledLabel>Title</StyledLabel>
          <StyledInput
            type="text"
            value={title}
            onChange={handleTitleChange}
            placeholder="Enter title"
          />
        </TitleAndInputWrapper>

        {/* Features Input */}
        <FeaturesWrapper>
          <StyledLabel>Create features</StyledLabel>
          <FeatureInputWrapper>
            <StyledInput
              type="text"
              value={currentFeature}
              onChange={handleFeatureChange}
              placeholder="Feature name"
            />
            <AddButton onClick={addFeature}>
              {editingFeatureIndex !== null ? 'Edit' : 'Add'}
            </AddButton>
          </FeatureInputWrapper>
          {features?.length > 0 && (
            <>
              {features.map((feature, index) => (
                <FeatureItem key={index}>
                  {feature}
                  <EditDeleteIcons>
                    <div onClick={() => editFeature(index)}>
                      <EditIcon width={16} height={16} />
                    </div>
                    <div onClick={() => removeFeature(index)}>
                      <DeleteIcon width={16} height={16} />
                    </div>
                  </EditDeleteIcons>
                </FeatureItem>
              ))}
            </>
          )}
        </FeaturesWrapper>

        {/* Discount Promotions */}
        <TitleAndInputWrapper>
          <StyledLabel>Discount promotions</StyledLabel>
          <DiscountWrapper>
            <StyledDateInput
              type="date"
              name="from"
              value={discount.from}
              onChange={handleDiscountChange}
            />
            <StyledDateInput
              type="date"
              name="to"
              value={discount.to}
              onChange={handleDiscountChange}
            />
            <div style={{ display: 'flex', width: '100%', gap: '16px' }}>
              <StyledInput
                type="text"
                name="price"
                value={discount.price}
                onChange={handleDiscountChange}
                placeholder="Price"
              />
              <StyledSelect
                name="currency"
                value={discount.currency}
                onChange={handleDiscountChange}
              >
                <option value="USD">$</option>
                <option value="EUR">€</option>
                <option value="%">%</option>
              </StyledSelect>
            </div>
          </DiscountWrapper>
        </TitleAndInputWrapper>

        {/* Price Input */}
        <TitleAndInputWrapper>
          <StyledLabel>Price</StyledLabel>
          <PriceWrapper>
            <StyledInput
              type="text"
              name="amount"
              value={price.amount}
              onChange={handlePriceChange}
              placeholder="Type price plan"
            />
            <StyledSelect
              value={price.currency}
              onChange={handlePriceCurrencyChange}
            >
              <option value="USD">$</option>
              <option value="EUR">€</option>
            </StyledSelect>
          </PriceWrapper>
        </TitleAndInputWrapper>

        <ButtonWrapper>
          <BackButton onClick={closeModal}>Back to Dashboard</BackButton>
          <CreateButton onClick={handleSubmit}>
            {selectedPlan ? 'Update' : 'Create'}
          </CreateButton>
        </ButtonWrapper>
      </ModalContent>
    </Wrapper>
  );
};

export default Modal;
