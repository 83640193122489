import React, { useEffect, useState } from 'react';
import { Sidebar } from '../../layout/sidebar/sidebar';
import {
  ContentWrapper,
  LayoutContainer,
  NavbarAndContentContainer,
  ContentAndButtonsContainer,
  MainContentWrapper,
} from '../../layout/layout-container.styled';
import { useWindowHeight } from '../../../style-utils/use-window-height';
import { TopMenu } from '../../layout/top-menu/top-menu';
import { Text, font } from '../../../style-utils/text';
import { NavigateFunction } from 'react-router-dom';
import { Cookie } from 'universal-cookie';
import { AddAdminInputs } from './add-admin-inputs';
import { Chat } from '../../chat/chat';
import { AddAdminModal } from '../super-admin/super-admin-main/modal/modal';
import { colors } from '../../../style-utils/colors';
import MessagePopup from '../../messages/message-popup/message-popup';
// import { createItem } from '../../requests/requests';

interface EmployeesProps {
  navigate: NavigateFunction;
  // eslint-disable-next-line
  company: any;
  // eslint-disable-next-line
  cookies?: Cookie;
}

export const AddAdmin: React.FC<EmployeesProps> = ({ navigate, company }) => {
  const { name, id } = company;

  const height = useWindowHeight();
  console.log(name, id);

  const [addAdminModalVisible, setAddAdminModalVisible] =
    useState<boolean>(true);
  const [popUp, setPopUp] = useState<{
    text: string;
    type: 'success' | 'error';
  } | null>(null);

  const closeAddAdminModal = () => {
    setAddAdminModalVisible(false);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (popUp) {
      timer = setTimeout(() => {
        setPopUp(null);
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [popUp]);

  return (
    <LayoutContainer height={height}>
      <TopMenu />
      <MainContentWrapper>
        <Sidebar company={company} selectedOption={''} navigate={navigate} />
        <ContentWrapper>
          <NavbarAndContentContainer>
            <ContentAndButtonsContainer>
              <Text
                fontSize={30}
                mb={0}
                fontWeight={700}
                fontFamily={font.family}
                color={colors.black}
              >
                Add A New Admin
              </Text>
              <AddAdminInputs name={name} id={id} />
            </ContentAndButtonsContainer>
          </NavbarAndContentContainer>
        </ContentWrapper>
      </MainContentWrapper>
      <Chat />
      {addAdminModalVisible && (
        <AddAdminModal
          onClose={closeAddAdminModal}
          fromPage="adminSidebar"
          setPopUp={setPopUp}
          companyId={company.id}
        />
      )}
      {popUp && <MessagePopup text={popUp.text} type={popUp.type} />}
    </LayoutContainer>
  );
};
