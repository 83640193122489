import React, { useState } from 'react';
import { SignUpData } from '../hooks/use-auth-forms';
import { PaymentPlans } from '../payment-plans/payment-plan';
import { StepHeader } from './step-header';
import StripeSubscription from '../subscriptions/stripe';
import { PayPalSubscription } from '../subscriptions/paypal';
import { BlueButton, ButtonTitle } from '../login/login.styled';
import styled from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ErrorLabel } from '../pages/jobs/jobs.styled';

// Load your Stripe public key
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''
);

interface Step4Props {
  signUpData: SignUpData;
  setSignUpData: React.Dispatch<React.SetStateAction<SignUpData>>;
  currentStep: number;
  totalSteps: number;
  handlePrevious: () => void;
  handleRegister: () => void;
  errorMessage: string | null;
}

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 32px;
`;

const Step4: React.FC<Step4Props> = ({
  signUpData,
  setSignUpData,
  currentStep,
  totalSteps,
  handlePrevious,
  handleRegister,
  errorMessage,
}) => {
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const [amount, setAmount] = useState<number>(0);
  const [noSelectedPlanErrorMsg, setNoSelectedPlanErrorMsg] =
    useState<string>('');

  const handlePlanSelection = (planId: string, amount: number) => {
    setSelectedPlan(planId);
    setAmount(amount);
    setNoSelectedPlanErrorMsg('');
  };

  return (
    <>
      <StepHeader
        currentStep={currentStep}
        totalSteps={totalSteps}
        title="Step 4: Subscription Plan"
      />
      <PaymentPlans
        signUpData={signUpData}
        setSignUpData={setSignUpData}
        setSelectedPlan={handlePlanSelection}
      />
      {selectedPlan && (
        <>
          <Elements stripe={stripePromise}>
            <StripeSubscription
              email={signUpData.email}
              planId={selectedPlan}
              amount={amount}
            />
          </Elements>
          <PayPalSubscription amount={amount} />
        </>
      )}
      {noSelectedPlanErrorMsg && (
        <ErrorLabel>{noSelectedPlanErrorMsg}</ErrorLabel>
      )}
      {errorMessage && <ErrorLabel>{errorMessage}</ErrorLabel>}
      <ButtonsContainer>
        {currentStep > 1 && (
          <BlueButton type="button" onClick={handlePrevious}>
            <ButtonTitle fontColor="white">Previous</ButtonTitle>
          </BlueButton>
        )}
        <BlueButton
          type="button"
          onClick={() => {
            if (!selectedPlan) {
              setNoSelectedPlanErrorMsg('Please select a payment plan');
            } else {
              setNoSelectedPlanErrorMsg('');
              handleRegister();
            }
          }}
        >
          <ButtonTitle fontColor="white">
            Send Verification Code to complete Profile
          </ButtonTitle>
        </BlueButton>
      </ButtonsContainer>
    </>
  );
};

export default Step4;
