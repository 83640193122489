import React, { useEffect, useState } from 'react';
import { useJobOffer } from '../../hooks/global-job-offer-provider';
import {
  InputErrorMsgWrapper,
  StyledArrowIconWrapper,
  StyledBackBtn,
  StyledCreateNewJobForm,
  StyledDropDownMenuSelectInput,
  StyledFlowStepWrapper,
  StyledFormInputAndErrorWrapper,
  StyledGridInputsWrapper,
  StyledInputAndLabelWrapper,
  StyledNavButtonsWrapper,
  StyledPublishBtn,
  StyledPublishDraftBtn,
  StyledPublishedAndDraftBtnWrapper,
  StyledSelectInputAndArrowIconWrapper,
  StyledStepIndicatorWrapper,
  StyledTextInput,
  StyledTitleAndPageIndicatorWrapper,
} from '../../create-job-offer-flow.styled';
import { font, Text } from '../../../../../../style-utils/text';
import { useFormik } from 'formik';
import { CreateNewJobThirdStepTypes } from '../../../../../../interfaces/job-interfaces';
import { createNewJobThirdStepSchema } from '../../validation-schemas';
import { colors } from '../../../../../../style-utils/colors';
import { SelectFieldDownArrowIcon } from '../../../../../icons/select-field-down-arrow-icon';
import { ErrorMsgLabelIcon } from '../../../../../icons/error-label-icon';
import { BackArrow } from '../../../../../icons/back-arrow';
import StepIndicator from '../../step-indicator/step-indicator';
import { Company } from '../../../../../app-router/component-props';
import { SelectDate } from '../../../../applicants/candidates/date-picker';
import { TextEditor } from '../../../../../styled-quill/text-editor';

interface ThirdStepFormPropsTypes {
  user: Company;
  handlePrevStep: () => void;
}

export const AboutCompany: React.FC<ThirdStepFormPropsTypes> = ({
  user,
  handlePrevStep,
}) => {
  const { jobOffer, updateJobOffer, submitJobOffer, key } = useJobOffer();
  const [validateOnChange, setValidateOnChange] = useState(false);
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [loadingDraft, setLoadingDraft] = useState(false);

  useEffect(() => {
    updateJobOffer('company_id', user.id);
  }, [user]);

  const thirdStepInitValues: CreateNewJobThirdStepTypes = {
    general_company_info: jobOffer.general_company_info,
    work_location: jobOffer.work_location,
    location: jobOffer.location,
    industry: jobOffer.industry,
    benefits: jobOffer.benefits,
    expiration_date: jobOffer.expiration_date,
  };

  const formik = useFormik({
    initialValues: thirdStepInitValues,
    validationSchema: createNewJobThirdStepSchema,
    validateOnChange: validateOnChange,
    validateOnBlur: validateOnChange,
    onSubmit: async () => {
      if (jobOffer.status === 'draft') {
        setLoadingDraft(true);
      } else {
        setLoadingPublish(true);
      }
      console.log('All Values', jobOffer);
      await submitJobOffer(user.name);
      setLoadingDraft(false);
      setLoadingPublish(false);
    },
  });

  const handleExpirationDateChange = (date: Date | null) => {
    console.log('Selected expiration date: ', date);
    if (date) {
      const formattedDate = formatDate(date);
      console.log('Formatted expiration date: ', formattedDate);
      updateJobOffer('expiration_date', formattedDate);
      formik.setFieldValue('expiration_date', formattedDate);
    }
  };

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const hours = '00';
    const minutes = '00';
    const seconds = '00';

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}+00`;
  };

  return (
    <StyledFlowStepWrapper key={key}>
      <StyledTitleAndPageIndicatorWrapper>
        <StyledStepIndicatorWrapper>
          <StepIndicator currentStep={3} numberOfSteps={3} />
        </StyledStepIndicatorWrapper>
      </StyledTitleAndPageIndicatorWrapper>
      <StyledCreateNewJobForm
        onChange={formik.handleChange}
        onSubmit={formik.handleSubmit}
      >
        {/* General Company Information */}
        <StyledFormInputAndErrorWrapper>
          <StyledInputAndLabelWrapper>
            <Text
              fontFamily={font.family}
              color={colors.neutral_400}
              fontWeight={600}
              fontSize={16}
              margin="0"
            >
              General Company Information
            </Text>
            <TextEditor
              height="150px"
              minHeight="150px"
              placeholder="Enter General Company Info"
              changeHandler={(value) => {
                updateJobOffer('general_company_info', value);
                formik.setFieldValue('general_company_info', value);
              }}
              defaultValue={jobOffer.general_company_info}
            />
          </StyledInputAndLabelWrapper>
          {formik.errors.general_company_info && (
            <InputErrorMsgWrapper>
              <ErrorMsgLabelIcon />
              <Text
                fontFamily={font.family}
                color={colors.error}
                fontWeight={400}
                fontSize={12}
                margin="0"
              >
                {formik.errors.general_company_info}
              </Text>
            </InputErrorMsgWrapper>
          )}
        </StyledFormInputAndErrorWrapper>
        {/* General Company Information END*/}
        <StyledGridInputsWrapper>
          {/* Work Location */}
          <StyledFormInputAndErrorWrapper>
            <StyledInputAndLabelWrapper>
              <Text
                fontFamily={font.family}
                color={colors.neutral_400}
                fontWeight={600}
                fontSize={16}
                margin="0"
              >
                Work Location
              </Text>
              <StyledSelectInputAndArrowIconWrapper>
                <StyledArrowIconWrapper>
                  <SelectFieldDownArrowIcon />
                </StyledArrowIconWrapper>
                <StyledDropDownMenuSelectInput
                  placeholder="Work Location"
                  name="work_location"
                  value={formik.values.work_location}
                  onChange={(e) =>
                    updateJobOffer('work_location', e.target.value)
                  }
                >
                  <option value={''} disabled>
                    Select a work location
                  </option>
                  <option value={'Office'}>Office</option>
                  <option value={'Remote'}>Remote</option>
                  <option value={'Hybrid'}>Hybrid</option>
                </StyledDropDownMenuSelectInput>
              </StyledSelectInputAndArrowIconWrapper>
            </StyledInputAndLabelWrapper>
            {formik.errors.work_location && (
              <InputErrorMsgWrapper>
                <ErrorMsgLabelIcon />
                <Text
                  fontFamily={font.family}
                  color={colors.error}
                  fontWeight={400}
                  fontSize={12}
                  margin="0"
                >
                  {formik.errors.work_location}
                </Text>
              </InputErrorMsgWrapper>
            )}
          </StyledFormInputAndErrorWrapper>
          {/* Work Location END */}
          {/* Industry */}
          <StyledFormInputAndErrorWrapper>
            <StyledInputAndLabelWrapper>
              <Text
                fontFamily={font.family}
                color={colors.neutral_400}
                fontWeight={600}
                fontSize={16}
                margin="0"
              >
                Industry
              </Text>
              <StyledTextInput
                placeholder="Industry"
                name="industry"
                value={formik.values.industry}
                onChange={(e) => updateJobOffer('industry', e.target.value)}
              />
            </StyledInputAndLabelWrapper>
            {formik.errors.industry && (
              <InputErrorMsgWrapper>
                <ErrorMsgLabelIcon />
                <Text
                  fontFamily={font.family}
                  color={colors.error}
                  fontWeight={400}
                  fontSize={12}
                  margin="0"
                >
                  {formik.errors.industry}
                </Text>
              </InputErrorMsgWrapper>
            )}
          </StyledFormInputAndErrorWrapper>
          {/* Industry END*/}
        </StyledGridInputsWrapper>
        {/* Location (city, state, country...) */}
        <StyledGridInputsWrapper>
          <StyledFormInputAndErrorWrapper>
            <StyledInputAndLabelWrapper>
              <Text
                fontFamily={font.family}
                color={colors.neutral_400}
                fontWeight={600}
                fontSize={16}
                margin="0"
              >
                Location (city, state, country...)
              </Text>
              <StyledTextInput
                placeholder="Location (city, state, country...)"
                name="location"
                value={formik.values.location}
                onChange={(e) => updateJobOffer('location', e.target.value)}
              />
            </StyledInputAndLabelWrapper>
            {formik.errors.location && (
              <InputErrorMsgWrapper>
                <ErrorMsgLabelIcon />
                <Text
                  fontFamily={font.family}
                  color={colors.error}
                  fontWeight={400}
                  fontSize={12}
                  margin="0"
                >
                  {formik.errors.location}
                </Text>
              </InputErrorMsgWrapper>
            )}
          </StyledFormInputAndErrorWrapper>
          <StyledFormInputAndErrorWrapper>
            <StyledInputAndLabelWrapper>
              <Text
                fontFamily={font.family}
                color={colors.neutral_400}
                fontWeight={600}
                fontSize={16}
                margin="0"
              >
                Expiration date
              </Text>
              <SelectDate
                defaultValue={new Date(formik.values.expiration_date)}
                onChange={handleExpirationDateChange}
                style={{
                  width: '100%',
                  borderRadius: '16px',
                  borderStyle: 'none',
                  height: '48px',
                  padding: '0 12px',
                }}
              />
            </StyledInputAndLabelWrapper>
            {formik.errors.expiration_date && (
              <InputErrorMsgWrapper>
                <ErrorMsgLabelIcon />
                <Text
                  fontFamily={font.family}
                  color={colors.error}
                  fontWeight={400}
                  fontSize={12}
                  margin="0"
                >
                  {formik.errors.expiration_date}
                </Text>
              </InputErrorMsgWrapper>
            )}
          </StyledFormInputAndErrorWrapper>
        </StyledGridInputsWrapper>
        {/* Location (city, state, country...) END*/}
        {/* Benefits */}
        <StyledFormInputAndErrorWrapper>
          <StyledInputAndLabelWrapper>
            <Text
              fontFamily={font.family}
              color={colors.neutral_400}
              fontWeight={600}
              fontSize={16}
              margin="0"
            >
              Benefits
            </Text>
            <TextEditor
              height="150px"
              minHeight="150px"
              placeholder="Benefits"
              changeHandler={(value) => {
                updateJobOffer('benefits', value);
                formik.setFieldValue('benefits', value);
              }}
              defaultValue={jobOffer.benefits}
            />
          </StyledInputAndLabelWrapper>
          {formik.errors.benefits && (
            <InputErrorMsgWrapper>
              <ErrorMsgLabelIcon />
              <Text
                fontFamily={font.family}
                color={colors.error}
                fontWeight={400}
                fontSize={12}
                margin="0"
              >
                {formik.errors.benefits}
              </Text>
            </InputErrorMsgWrapper>
          )}
        </StyledFormInputAndErrorWrapper>
        {/* Benefits END*/}
        <StyledNavButtonsWrapper>
          <StyledBackBtn type="button" onClick={() => handlePrevStep()}>
            <BackArrow />
            Back
          </StyledBackBtn>
          <StyledPublishedAndDraftBtnWrapper>
            <StyledPublishDraftBtn
              disabled={false}
              type="submit"
              onClick={() => {
                setValidateOnChange(true);
                updateJobOffer('status', 'draft');
              }}
            >
              {loadingDraft ? 'Saving ...' : 'Save as Draft'}
            </StyledPublishDraftBtn>
            <StyledPublishBtn
              disabled={false}
              type="submit"
              onClick={() => {
                setValidateOnChange(true);
                updateJobOffer('status', 'active');
              }}
            >
              {loadingPublish ? 'Publishing ...' : 'Publish'}
            </StyledPublishBtn>
          </StyledPublishedAndDraftBtnWrapper>
        </StyledNavButtonsWrapper>
      </StyledCreateNewJobForm>
    </StyledFlowStepWrapper>
  );
};
