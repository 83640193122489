import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { apiRoute } from '../../../constants/api-constants';
import { Spinner } from '../../icons/spinner';

interface ErrorResponse {
  message: string;
  // Include other fields that might be in your error response
}

export const JobOfferConfirmation = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const confirmJobOffer = async () => {
      const token = searchParams.get('token');
      if (!token) {
        console.log('Token not found');
        navigate('/');

        return;
      }

      try {
        const res = await axios.post(`${apiRoute}/confirm-job-offer`, {
          token,
        });
        console.log('API Response:', res.data);
      } catch (error) {
        console.error('Error verifying email:', error as AxiosError);
        const displayError = error as AxiosError<ErrorResponse>;
        setError(
          (displayError.response?.data?.message as string) ||
            'Error verifying email'
        );
      } finally {
        setIsLoading(false);
      }
    };

    confirmJobOffer();
  }, [searchParams, navigate]);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <h2 style={{ textAlign: 'center', marginTop: '150px' }}>
      Thank you for excepting the offer!
    </h2>
  );
};
