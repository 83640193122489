import React, { useEffect, useState } from 'react';
import {
  ButtonContainer,
  ButtonTitle,
  Dropdown,
  ItemsContainer,
  MenuItem,
  SidebarContainer,
} from './sidebar.styled';
import { NavigateFunction, useLocation } from 'react-router-dom';
import { Text, font } from '../../../style-utils/text';
import { Company } from '../../app-router/component-props';
import { useWindowHeight } from '../../../style-utils/use-window-height';
import { logOut } from '../../../function-utils/log-out';
import { useAuth } from '../../../context/auth-provider';

interface SuperAdminSidebarProps {
  navigate?: NavigateFunction;
  company: Company;
}

export const SuperAdminSidebar: React.FC<SuperAdminSidebarProps> = ({
  navigate,
  company,
}) => {
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const location = useLocation();
  const height = useWindowHeight();
  const { id } = company;

  const handleDropdownClick = (dropdownName: string, path: string) => {
    if (openDropdown === dropdownName) {
      return;
    }
    setOpenDropdown(dropdownName);
    navigate && navigate(path);
  };

  const getSidebarActiveButtonAccordingUrl = () => {
    const lastPartUrl = location.pathname.split('/').pop();
    switch (lastPartUrl) {
      case 'super-admin':
        return setOpenDropdown('Dashboard');
      case 'companies':
        return setOpenDropdown('Companies');
      case 'admins':
        return setOpenDropdown('Admins');
      case 'settings':
        return setOpenDropdown('Settings');
      case 'super-admin':
        return setOpenDropdown('Super Admin');
      case 'subscription-plans':
        return setOpenDropdown('Subscription Plans');
      default:
        return setOpenDropdown('');
    }
  };

  useEffect(() => {
    getSidebarActiveButtonAccordingUrl();
  }, [location.pathname]);

  const { setUser } = useAuth();

  return (
    <SidebarContainer height={height} fromPage={company.entityType}>
      {company.entityType === 'company' && (
        <ItemsContainer id="items container">
          <Dropdown
            id="admin-dashboard-dropdown"
            isActive={openDropdown === 'Dashboard'}
            onClick={() => handleDropdownClick('Dashboard', `/super-admin`)}
          >
            <Text pl={8} fontFamily={font.family}>
              <strong>Dashboard</strong>
            </Text>
          </Dropdown>

          <Dropdown
            id="company-dropdown"
            isActive={openDropdown === 'Companies'}
            onClick={() =>
              handleDropdownClick('Companies', `/${id}/super-admin/companies`)
            }
          >
            <MenuItem>
              <Text pl={8} fontFamily={font.family}>
                <strong>Companies</strong>
              </Text>
            </MenuItem>
          </Dropdown>

          <Dropdown
            id="admins-dropdown"
            isActive={openDropdown === 'Admins'}
            onClick={() =>
              handleDropdownClick('Admins', `/${id}/super-admin/admins`)
            }
          >
            <MenuItem>
              <Text pl={8} fontFamily={font.family}>
                <strong>Admins</strong>
              </Text>
            </MenuItem>
          </Dropdown>

          <Dropdown
            id="settings-dropdown"
            isActive={openDropdown === 'Settings'}
            onClick={() =>
              handleDropdownClick('Settings', `/${id}/super-admin/settings`)
            }
          >
            <MenuItem>
              <Text pl={8} fontFamily={font.family}>
                <strong>Settings</strong>
              </Text>
            </MenuItem>
          </Dropdown>
          <Dropdown
            id="subscription-plans-dropdown"
            isActive={openDropdown === 'Subscription Plans'}
            onClick={() =>
              handleDropdownClick(
                'Subscription Plans',
                `/${id}/super-admin/subscription-plans`
              )
            }
          >
            <MenuItem>
              <Text pl={8} fontFamily={font.family}>
                <strong>Subscription Plans</strong>
              </Text>
            </MenuItem>
          </Dropdown>
        </ItemsContainer>
      )}
      <br />
      <ButtonContainer onClick={() => logOut(setUser, navigate && navigate)}>
        <ButtonTitle>Logout</ButtonTitle>
      </ButtonContainer>
    </SidebarContainer>
  );
};
