import React, { useState, useRef } from 'react';
import moxelleicon from '../icons/moxelle.png';
import { PlaneIcon } from '../icons/send-plane';
import { SendMessage } from '../icons/send-msg';
import { Cookie } from 'universal-cookie';
import {
  ChatButton,
  ChatContainer,
  LeftChatHeader,
  ChatHeader,
  CloseChatBtn,
  ChatContent,
  AutoMessage,
  ChatMessages,
  Message,
  QuickQuestions,
  QuestionBtn,
  ChatInput,
  UserMessage,
  SendBtn,
} from './chat.styled';

interface ChatMessage {
  text: string;
  sent?: boolean;
  cookies?: Cookie;
}

export const Chat: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [showQuickQuestions, setShowQuickQuestions] = useState(true);

  const chatContainerRef = useRef<HTMLDivElement>(null);

  const quickQuestions = [
    'When will my contract expire?',
    'What is my current salary without benefits?',
    'How many days off i have remaining?',
  ];

  const handleSendMessage = () => {
    if (inputValue.trim()) {
      setMessages([...messages, { text: inputValue }]);
      setInputValue('');
      setShowQuickQuestions(false);
    }
  };

  const handleQuickQuestionClick = (question: string) => {
    setMessages([
      ...messages,
      { text: `Predefined answer for: ${question}`, sent: true },
    ]);
    setShowQuickQuestions(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleChatToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {!isOpen && (
        <ChatButton onClick={handleChatToggle}>
          Ask me anything...
          <PlaneIcon />
        </ChatButton>
      )}
      {isOpen && (
        <ChatContainer ref={chatContainerRef}>
          <ChatHeader>
            <LeftChatHeader>
              <img src={moxelleicon} width={22} />
              <p>Moxelle</p>
            </LeftChatHeader>
            <CloseChatBtn onClick={handleChatToggle}>x</CloseChatBtn>
          </ChatHeader>
          <ChatContent>
            <AutoMessage>
              Welcome to <b>WorWise</b>! How can we help you?
            </AutoMessage>
            <ChatMessages>
              {messages.map((msg, i) => (
                <Message key={i} sent={msg.sent}>
                  {msg.text}
                </Message>
              ))}
            </ChatMessages>
            {showQuickQuestions && (
              <QuickQuestions>
                {quickQuestions.map((question, i) => (
                  <QuestionBtn
                    key={i}
                    onClick={() => handleQuickQuestionClick(question)}
                  >
                    {question}
                  </QuestionBtn>
                ))}
              </QuickQuestions>
            )}
          </ChatContent>
          <ChatInput>
            <UserMessage
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Type your message..."
            />
            <SendBtn onClick={handleSendMessage}>
              <SendMessage />
            </SendBtn>
          </ChatInput>
        </ChatContainer>
      )}
    </>
  );
};
