import React, { useEffect, useState } from 'react';
import {
  ApplicantsContentWrapper,
  HeadingWrapper,
  StyledTable,
  StyledTd,
  StyledTh,
  StyledThead,
  StyledTr,
  WrapperDiv,
  StyledSelect,
  StyledSearchInput,
  SuperAdminHeader,
} from '../../employees/employees.styled';
import { ButtonContainer } from '../../../login/login.styled';
import { Text, font } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';
import { usePagination } from '../../../hooks/use-pagination';
import { PaginationButtons } from '../../../table-pagination/table-pagination';
import { formatDateToLocal } from '../../../../function-utils/utc-date-converter';
import { SubscriptionPlan } from './interface';
import { Dropdown } from './dropdown-button';
import { capitalizeFirstLetter } from '../../../../function-utils/capitalize-first-letter';

interface SubscriptionPlansProps {
  handleAddNewPlan: () => void;
  setIsPlanOpen: (isOpen: boolean) => void;
  subscriptionPlans: SubscriptionPlan[];
  handleEditPlan: (plan: SubscriptionPlan) => void;
  deactivatePlan: (id: string) => void;
  activatePlan: (id: string) => void;
}

export const SubscriptionPlans: React.FC<SubscriptionPlansProps> = ({
  handleAddNewPlan,
  setIsPlanOpen,
  subscriptionPlans,
  handleEditPlan,
  deactivatePlan,
  activatePlan,
}) => {
  const [filteredPlans, setFilteredPlans] = useState<SubscriptionPlan[]>([]);
  const [filterBy, setFilterBy] = useState('');
  const [searchWord, setSearchWord] = useState('');

  useEffect(() => {
    const filterAndSearch = () => {
      let filtered = subscriptionPlans;

      if (searchWord) {
        const lowerCaseSearchWord = searchWord.toLowerCase();
        filtered = filtered.filter((plan) =>
          plan.title.toLowerCase().includes(lowerCaseSearchWord)
        );
      }

      if (filterBy) {
        filtered = filtered.filter((plan) => {
          const lowerCaseSearchWord = searchWord.toLowerCase();
          switch (filterBy) {
            case 'Create Date':
              return plan.createdAt.toLowerCase().includes(lowerCaseSearchWord);
            case 'Subscription Plan':
              return plan.title.toLowerCase().includes(lowerCaseSearchWord);
            default:
              return true;
          }
        });
      }

      setFilteredPlans(filtered);
    };

    filterAndSearch();
  }, [filterBy, searchWord, subscriptionPlans]);

  const {
    currentPage,
    displayedData,
    nextPage,
    previousPage,
    hasPreviousPage,
    hasNextPage,
    getTotalPages,
  } = usePagination(filteredPlans, 5);

  return (
    <ApplicantsContentWrapper>
      <div style={{ overflowX: 'auto' }}>
        <HeadingWrapper>
          <SuperAdminHeader>Subscription Plans</SuperAdminHeader>
          <WrapperDiv>
            <StyledSearchInput
              type="text"
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
              placeholder="Search..."
            />
            <StyledSelect
              value={filterBy}
              onChange={(e) => setFilterBy(e.target.value)}
            >
              <option value="">Filter by</option>
              <option value="Create Date">Create Date</option>
              <option value="Subscription Plan">Subscription Plan</option>
            </StyledSelect>
            <ButtonContainer
              backgroundColor={colors.secondary_400}
              onClick={handleAddNewPlan}
            >
              <Text
                mt={0}
                mb={0}
                align="center"
                pl={20}
                pr={20}
                fontFamily={font.family}
                fontWeight={700}
              >
                Add new plan
              </Text>
            </ButtonContainer>
          </WrapperDiv>
        </HeadingWrapper>
        <StyledTable>
          <StyledThead>
            <StyledTr>
              <StyledTh columnWidth={'120px'}>Create Date</StyledTh>
              <StyledTh columnWidth={'694px'}>Subscription Plan Name</StyledTh>
              <StyledTh columnWidth={'120px'}>Status</StyledTh>
              <StyledTh columnWidth={'120px'}>Action</StyledTh>
            </StyledTr>
          </StyledThead>
          <tbody>
            {displayedData?.map((plan, index) => (
              <StyledTr key={index}>
                <StyledTd data-label="created-at">
                  {formatDateToLocal(plan.createdAt)}
                </StyledTd>
                <StyledTd data-label="title">{plan?.title}</StyledTd>
                <StyledTd data-label="status">
                  <Text
                    fontFamily={font.family}
                    color={plan?.status === 'active' ? 'green' : 'red'}
                  >
                    {capitalizeFirstLetter(plan?.status)}
                  </Text>
                </StyledTd>
                <StyledTd>
                  <StyledTd>
                    <Dropdown
                      onActivate={() => activatePlan(plan.id)}
                      onClick={() => setIsPlanOpen(true)}
                      onEdit={() => handleEditPlan(plan)}
                      onDeactivate={() => deactivatePlan(plan.id)}
                      planStatus={plan?.status}
                    />
                  </StyledTd>
                </StyledTd>
              </StyledTr>
            ))}
          </tbody>
        </StyledTable>
      </div>
      <div style={{ width: '60%' }}>
        <PaginationButtons
          currentPage={currentPage}
          hasPreviousPage={hasPreviousPage}
          hasNextPage={hasNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
          totalPages={getTotalPages()}
        />
      </div>
    </ApplicantsContentWrapper>
  );
};
