import React, { useState } from 'react';
import axios from 'axios';
import {
  StyledCandidateAddNewCommentWrapper,
  StyledCandidateCommentHeader,
  StyledCandidateCommentTextArea,
  StyledCandidateCommentWrapper,
  StyledSubmitNewCommentBtn,
} from '../candidate-comments.styled';
import { Text, font } from '../../../../../../../style-utils/text';
import { colors } from '../../../../../../../style-utils/colors';
import { PlusButton } from '../../../../../../icons/plus-icon';
import { apiRoute } from '../../../../../../../constants/api-constants';

interface CandidateAddNewCommentsPropTypes {
  // eslint-disable-next-line
  company: any;
  candidateId: string;
  loadComments: () => void;
}

const CandidateAddNewComment: React.FC<CandidateAddNewCommentsPropTypes> = ({
  company,
  candidateId,
  loadComments,
}) => {
  const baseURL = apiRoute;
  const [commentText, setCommentText] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const todayDate = new Date().toLocaleDateString();

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommentText(e.target.value);
  };

  const handleAddComment = () => {
    if (commentText.trim() === '') {
      return;
    }

    setIsSubmitting(true);

    axios
      .post(`${baseURL}/applicant/comments`, {
        applicant_id: candidateId,
        author_name: company.name,
        author_id: company.id,
        comment: commentText,
      })
      .then((response) => {
        setCommentText('');
        loadComments();
        console.log(response);
      })
      .catch((error) => {
        console.error('Failed to add comment:', error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <StyledCandidateAddNewCommentWrapper>
      <StyledCandidateCommentWrapper>
        <StyledCandidateCommentHeader>
          <Text
            fontWeight={700}
            fontSize={16}
            fontFamily={font.family}
            color={colors.neutral_900}
            margin="0"
            lineHeight="normal"
          >
            {company.name}
          </Text>
          <Text
            fontWeight={300}
            fontSize={12}
            fontFamily={font.family}
            color={colors.neutral_700}
            margin="0"
            lineHeight="normal"
          >
            {todayDate}
          </Text>
        </StyledCandidateCommentHeader>
        <StyledCandidateCommentTextArea
          placeholder="Add Comment"
          value={commentText}
          onChange={handleCommentChange}
          disabled={isSubmitting}
        />
      </StyledCandidateCommentWrapper>
      <StyledSubmitNewCommentBtn
        onClick={handleAddComment}
        disabled={isSubmitting}
      >
        <PlusButton />
        Add new comment
      </StyledSubmitNewCommentBtn>
    </StyledCandidateAddNewCommentWrapper>
  );
};

export default CandidateAddNewComment;
