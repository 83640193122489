import React from 'react';
import { colors } from '../../../../../../../../../../style-utils/colors';
import { Text, font } from '../../../../../../../../../../style-utils/text';
import {
  EmployeeDetailDocumentTable,
  EmployeeDetailDocumentTableHeaders,
  EmployeeDetailDocumentTableHeader,
} from '../../../../../../../../employees/employee.styled';
import JobPositionTabTableActions from './components/actions/job-position-tab-table-actions';

interface JobPosition {
  id: number;
  createdAt: string;
  job_position: string;
}

interface JobPositionTabTableProps {
  data: JobPosition[];
  onEdit: (jobPosition: JobPosition) => void;
  onDelete: (jobPosition: JobPosition) => void;
}

const JobPositionTabTable: React.FC<JobPositionTabTableProps> = ({
  data,
  onEdit,
  onDelete,
}) => {
  if (!data || data.length === 0) {
    return (
      <Text
        fontFamily={font.family}
        margin="0"
        color={colors.base_black}
        fontSize={24}
        fontWeight={700}
        lineHeight="28.8px"
      >
        No Job Positions
      </Text>
    );
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);

    return date.toISOString().split('T')[0];
  };

  return (
    <EmployeeDetailDocumentTable>
      <EmployeeDetailDocumentTableHeaders>
        <EmployeeDetailDocumentTableHeader
          bgColor={colors.primary_500}
          padding="8px 16px"
          width="40%"
        >
          <Text
            fontFamily={font.family}
            margin="0"
            color={colors.base_white}
            fontSize={16}
            fontWeight={700}
            lineHeight="19.2px"
            align="center"
          >
            Position Title
          </Text>
        </EmployeeDetailDocumentTableHeader>
        <EmployeeDetailDocumentTableHeader
          bgColor={colors.primary_300}
          padding="8px 16px"
          width="40%"
        >
          <Text
            fontFamily={font.family}
            margin="0"
            color={colors.base_white}
            fontSize={16}
            fontWeight={700}
            lineHeight="19.2px"
            align="center"
          >
            Create Date
          </Text>
        </EmployeeDetailDocumentTableHeader>
        {/* <EmployeeDetailDocumentTableHeader
          bgColor={colors.primary_500}
          padding="8px 16px"
          width="350px"
        >
          <Text
            fontFamily={font.family}
            margin="0"
            color={colors.base_white}
            fontSize={16}
            fontWeight={700}
            lineHeight="19.2px"
            align="center"
          >
            ID
          </Text>
        </EmployeeDetailDocumentTableHeader> */}
        <EmployeeDetailDocumentTableHeader
          bgColor={colors.primary_300}
          padding="8px 16px"
          width="40%"
        >
          <Text
            fontFamily={font.family}
            margin="0"
            color={colors.base_white}
            fontSize={16}
            fontWeight={700}
            lineHeight="19.2px"
            align="center"
          >
            Action
          </Text>
        </EmployeeDetailDocumentTableHeader>
      </EmployeeDetailDocumentTableHeaders>
      {data.map((position) => (
        <EmployeeDetailDocumentTableHeaders key={position.id}>
          {/* <EmployeeDetailDocumentTableHeader
            bgColor={colors.base_white}
            padding="8px 16px"
            width="350px"
          >
            <Text
              fontFamily={font.family}
              margin="0"
              color={colors.base_black}
              fontSize={14}
              fontWeight={400}
              lineHeight="16.8px"
              align="center"
            >
              {position.id}
            </Text>
          </EmployeeDetailDocumentTableHeader> */}
          <EmployeeDetailDocumentTableHeader
            bgColor={colors.base_white}
            padding="8px 16px"
            width="40%"
          >
            <Text
              fontFamily={font.family}
              margin="0"
              color={colors.base_black}
              fontSize={14}
              fontWeight={400}
              lineHeight="16.8px"
              align="center"
            >
              {position.job_position}
            </Text>
          </EmployeeDetailDocumentTableHeader>
          <EmployeeDetailDocumentTableHeader
            bgColor={colors.base_white}
            padding="8px 16px"
            width="40%"
          >
            <Text
              fontFamily={font.family}
              margin="0"
              color={colors.base_black}
              fontSize={14}
              fontWeight={400}
              lineHeight="16.8px"
              align="center"
            >
              {formatDate(position.createdAt)}
            </Text>
          </EmployeeDetailDocumentTableHeader>
          <EmployeeDetailDocumentTableHeader
            bgColor={colors.base_white}
            padding="8px 16px"
            width="40%"
          >
            <JobPositionTabTableActions
              onEdit={() => onEdit(position)}
              onDelete={() => onDelete(position)}
            />
          </EmployeeDetailDocumentTableHeader>
        </EmployeeDetailDocumentTableHeaders>
      ))}
    </EmployeeDetailDocumentTable>
  );
};

export default JobPositionTabTable;
