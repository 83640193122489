import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../lp-images/white_logo.png';
import email from '../../lp-images/mail.png';
import location from '../../lp-images/location.png';
import facebookLogo from '../../lp-images/facebook.png';
import instagramLogo from '../../lp-images/instagram.png';
import {
  FooterContainer,
  ContentContainer,
  Logo,
  FooterLeft,
  FooterLists,
  ListItem,
  Links,
  ListItemLeft,
  EmailLink,
  ListTitle,
  FooterRight,
  FollowLinks,
  SocialMediaLinks,
  UnderFooterContainer,
  ContentUnder,
  Copyright,
  TermsPolicy,
  LinksDown,
} from './footer-lp.styled';

export const FooterLP: React.FC = () => {
  return (
    <>
      <FooterContainer>
        <ContentContainer>
          <FooterLeft>
            <FooterLists>
              <Logo src={logo} alt="logo" />
              <ListItemLeft>
                <b>Get in touch</b>
              </ListItemLeft>
              <ListItemLeft>
                <img src={email} alt="email icon" width={35} />
                <EmailLink href="mailto:worwise@moxelle.com">
                  worwise@moxelle.com
                </EmailLink>
              </ListItemLeft>
              <ListItemLeft>
                <img src={location} alt="location icon" width={35} />
                St. Majakovski 44/5, Skopje, N. Macedonia
              </ListItemLeft>
            </FooterLists>
          </FooterLeft>

          <FooterRight>
            <FooterLists>
              <ListTitle>Navigation</ListTitle>
              <ListItem>
                <Links href="#home">Home</Links>
              </ListItem>
              <ListItem>
                <Links href="#features">Features</Links>
              </ListItem>
              <ListItem>
                <Links href="#about">About</Links>
              </ListItem>
              <ListItem>
                <Links href="#pricing">Pricing</Links>
              </ListItem>
              <ListItem>
                <Links href="#faq">FAQ</Links>
              </ListItem>
            </FooterLists>

            <FollowLinks>
              <ListTitle>Follow us</ListTitle>
              <SocialMediaLinks>
                <Link to="https://www.facebook.com/profile.php?id=61564151662235">
                  <img src={facebookLogo} alt="facebook logo" width={35} />
                </Link>

                <Link to="https://www.instagram.com/worwise_/">
                  <img src={instagramLogo} alt="instagram logo" width={35} />
                </Link>
              </SocialMediaLinks>
            </FollowLinks>
          </FooterRight>
        </ContentContainer>
      </FooterContainer>
      <UnderFooterContainer>
        <ContentUnder>
          <Copyright>Copyright 2024 © all rights by Worwise</Copyright>
          <TermsPolicy>
            <LinksDown>
              <Link to="/info/terms">Terms Of Use</Link>
            </LinksDown>
            <LinksDown>
              <Link to="/info/privacy-policy">Privacy Policy</Link>
            </LinksDown>
          </TermsPolicy>
        </ContentUnder>
      </UnderFooterContainer>
    </>
  );
};
