import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Company } from '../../../app-router/component-props';
import { NewEmployeeDataTypes } from '../add-employee';
import {
  CheckboxWrapper,
  StyledEmployeeChecklistMainWrapper,
  StyledEmployeeChecklistName,
  StyledEmployeeChecklistNameCircle,
  StyledEmployeeChecklistSubmitBtn,
  StyledEmployeeOnboardingTabWMainWrapper,
  StyledOnboardingCategoriesListMainWrapper,
  StyledOnboardingChecklistOptionSelectedStatusIcon,
  StyledOptionsWrapper,
} from './employee-onboarding-tab.styled';
import { Text, font } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';
import { apiRoute } from '../../../../constants/api-constants';
import { OnboardingChecklistOptionSelected } from '../../../icons/onboarding-checklist-option-selected';
import { OnboardingChecklistOptionNotSelected } from '../../../icons/onboarding-checklist-option-notselected';
import MessagePopup from '../../../messages/message-popup/message-popup';

interface Props {
  company: Company;
  employee: NewEmployeeDataTypes;
}

interface ChecklistOption {
  checklist_option_id: string;
  option_name: string;
  selected: boolean;
}

interface Checklist {
  checklist_id: string;
  title: string;
  options: ChecklistOption[];
}

const EmployeeOnboardingTab: React.FC<Props> = ({ company, employee }) => {
  const baseURL = apiRoute;
  const [checklists, setChecklists] = useState<Checklist[]>([]);
  const [loading, setLoading] = useState(true);
  const [popUp, setPopUp] = useState<{
    text: string;
    type: 'success' | 'error';
  } | null>(null);

  useEffect(() => {
    const fetchChecklists = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/onboarding/checklists/employee/${company.id}/${employee.id}`
        );
        setChecklists(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch checklists:', error);
        setLoading(false);
      }
    };

    fetchChecklists();
  }, [company.id, employee.id]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (popUp) {
      timer = setTimeout(() => {
        setPopUp(null);
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [popUp]);

  const handleOptionChange = (
    checklistIndex: number,
    optionIndex: number,
    selected: boolean
  ) => {
    const updatedChecklists = [...checklists];
    updatedChecklists[checklistIndex].options[optionIndex].selected = selected;
    setChecklists(updatedChecklists);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await axios.post(
        `${baseURL}/onboarding/checklists/employee/${employee.id}/submit`,
        {
          checklists,
        }
      );
      setPopUp({
        text: 'Checklist submitted successfully!',
        type: 'success',
      });
    } catch (error) {
      console.error('Failed to submit checklist:', error);
      setPopUp({
        text: 'Failed to submit checklist',
        type: 'error',
      });
    }
  };

  if (loading) {
    return <Text>Loading...</Text>;
  }

  if (checklists.length === 0) {
    return (
      <StyledEmployeeOnboardingTabWMainWrapper>
        <Text>No checklists available for this company.</Text>
      </StyledEmployeeOnboardingTabWMainWrapper>
    );
  }

  return (
    <StyledEmployeeOnboardingTabWMainWrapper>
      {/* <StyledEditWrapper
        onClick={() => console.log('Onboarding edit clicked!')}
      >
        <Text
          fontWeight={700}
          color={colors.base_black}
          fontFamily={font.family}
          margin="0"
        >
          Edit
        </Text>
        <EditIcon />
      </StyledEditWrapper> */}
      <Text
        fontSize={24}
        fontWeight={700}
        color={colors.base_black}
        fontFamily={font.family}
        margin="0"
      >
        Employee Onboarding
      </Text>
      <StyledOnboardingCategoriesListMainWrapper onSubmit={handleSubmit}>
        {checklists.map((checklist, checklistIndex) => (
          <StyledEmployeeChecklistMainWrapper key={checklist.checklist_id}>
            <StyledEmployeeChecklistName>
              <StyledEmployeeChecklistNameCircle />
              <Text
                fontFamily={font.family}
                fontSize={18}
                fontWeight={700}
                color={colors.base_black}
                margin="0"
              >
                {checklist.title}
              </Text>
            </StyledEmployeeChecklistName>
            <StyledOptionsWrapper>
              {checklist.options.map((option, optionIndex) => (
                <CheckboxWrapper key={option.checklist_option_id}>
                  <StyledOnboardingChecklistOptionSelectedStatusIcon
                    onClick={() =>
                      handleOptionChange(
                        checklistIndex,
                        optionIndex,
                        !option.selected
                      )
                    }
                  >
                    {option.selected ? (
                      <OnboardingChecklistOptionSelected />
                    ) : (
                      <OnboardingChecklistOptionNotSelected />
                    )}
                  </StyledOnboardingChecklistOptionSelectedStatusIcon>
                  <Text
                    fontFamily={font.family}
                    fontSize={14}
                    fontWeight={400}
                    color={colors.base_black}
                    margin="0"
                  >
                    {option.option_name}
                  </Text>
                </CheckboxWrapper>
              ))}
            </StyledOptionsWrapper>
          </StyledEmployeeChecklistMainWrapper>
        ))}
        <StyledEmployeeChecklistSubmitBtn type="submit">
          <Text
            fontFamily={font.family}
            fontSize={16}
            fontWeight={700}
            color={colors.base_white}
            margin="0"
          >
            Submit
          </Text>
        </StyledEmployeeChecklistSubmitBtn>
      </StyledOnboardingCategoriesListMainWrapper>
      {popUp && <MessagePopup text={popUp.text} type={popUp.type} />}
    </StyledEmployeeOnboardingTabWMainWrapper>
  );
};

export default EmployeeOnboardingTab;
