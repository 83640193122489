import React, { useEffect, useState } from 'react';
import {
  ContentWrapper,
  LayoutContainer,
  NavbarAndContentContainer,
  MainContentWrapper,
} from '../../../layout/layout-container.styled';
import { useWindowHeight } from '../../../../style-utils/use-window-height';
import { TopMenu } from '../../../layout/top-menu/top-menu';
import { NavigateFunction } from 'react-router-dom';
import { Cookie } from 'universal-cookie';
import { SuperAdminSidebar } from '../../../layout/sidebar/super-admin-sidebar';
import { SuperAdmins } from './admins';
import { AddAdminModal } from './modal/modal'; // Import the modal component
import MessagePopup from '../../../messages/message-popup/message-popup';

interface SuperAdminTabProps {
  navigate: NavigateFunction;
  // eslint-disable-next-line
  user: any;
  cookies?: Cookie;
  viewAnalysis?: boolean;
  toggleAnalysis?: () => void;
}

export const SuperAdminTab: React.FC<SuperAdminTabProps> = ({
  navigate,
  user,
}) => {
  const height = useWindowHeight();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [popUp, setPopUp] = useState<{
    text: string;
    type: 'success' | 'error';
  } | null>(null);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (popUp) {
      timer = setTimeout(() => {
        setPopUp(null);
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [popUp]);

  return (
    <LayoutContainer height={height}>
      <TopMenu isSuperAdmin={user.account_type === 'superAdmin'} />
      <MainContentWrapper>
        <SuperAdminSidebar company={user} navigate={navigate} />
        <ContentWrapper>
          <NavbarAndContentContainer>
            <SuperAdmins openModal={openModal} />
            {isModalOpen && (
              <AddAdminModal onClose={closeModal} setPopUp={setPopUp} />
            )}
          </NavbarAndContentContainer>
        </ContentWrapper>
      </MainContentWrapper>
      {popUp && <MessagePopup text={popUp.text} type={popUp.type} />}
    </LayoutContainer>
  );
};
