import React, { useEffect, CSSProperties } from 'react';
import { DatePicker } from 'rsuite';
import { colors } from '../../../../style-utils/colors';

interface SelectDateProps {
  value?: Date | undefined | null;
  defaultValue?: Date | undefined | null;
  onChange: (value: Date | null) => void;
  style?: CSSProperties;
  placeholder?: string;
  onClean?: () => void;
}

export const SelectDate: React.FC<SelectDateProps> = ({
  value,
  onChange,
  style,
  defaultValue,
  placeholder = 'Select a date',
  onClean,
}) => {
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://unpkg.com/rsuite@5.65.0/dist/rsuite-no-reset.min.css';
    document.head.appendChild(link);

    // Cleanup function to remove the link if the component unmounts
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  const defaultStyle: CSSProperties = {
    boxSizing: 'border-box',
    display: 'flex',
    width: '280px',
    height: 'auto',
    borderRadius: '4px',
    color: colors.base_black,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightGray',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.base_white,
    ...style,
  };

  return (
    <DatePicker
      placeholder={placeholder}
      onChange={onChange}
      onClean={onClean}
      value={value}
      defaultValue={defaultValue}
      style={defaultStyle}
      editable={false} // Disabled set date by keyboard
    />
  );
};
