import React, { useEffect, useState } from 'react';
import {
  ContentWrapper,
  LayoutContainer,
  NavbarAndContentContainer,
  MainContentWrapper,
} from '../../../layout/layout-container.styled';
import { useWindowHeight } from '../../../../style-utils/use-window-height';
import { TopMenu } from '../../../layout/top-menu/top-menu';
import { NavigateFunction } from 'react-router-dom';
import { Cookie } from 'universal-cookie';
import { SuperAdminSidebar } from '../../../layout/sidebar/super-admin-sidebar';
import { SubscriptionPlans } from './subscription-plans';
import Modal from './modal/modal';
import axios from 'axios';
import { apiRoute } from '../../../../constants/api-constants';
import { Spinner } from '../../../icons/spinner';
import { SubscriptionPlan } from './interface';

interface SubscriptionPlansProps {
  navigate: NavigateFunction;
  // eslint-disable-next-line
  user: any;
  cookies?: Cookie;
  viewAnalysis?: boolean;
  toggleAnalysis?: () => void;
}

export const SubscriptionPlansPage: React.FC<SubscriptionPlansProps> = ({
  navigate,
  user,
}) => {
  const height = useWindowHeight();
  const [isPlanOpen, setIsPlanOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState<
    SubscriptionPlan[]
  >([]);
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan | null>(
    null
  );

  const handleAddNewPlan = () => {
    setSelectedPlan(null);
    setIsPlanOpen(true);
  };

  const handleEditPlan = (plan: SubscriptionPlan) => {
    setSelectedPlan(plan);
    setIsPlanOpen(true);
  };

  const closeModal = () => {
    setSelectedPlan(null);
    setIsPlanOpen(false);
  };

  const loadPlans = () => {
    setLoading(true);
    axios
      .get(`${apiRoute}/subscription-plan/all`)
      .then((response) => {
        console.log('Subscription plans response:', response.data);
        setSubscriptionPlans(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching subscription plans:', err);
        setLoading(false);
      });
  };

  const deactivatePlan = async (id: string) => {
    try {
      await axios.put(`${apiRoute}/subscription-plan/deactivate/${id}`);
      alert('Subscription plan deactivated successfully');
      loadPlans(); // Reload plans after deactivation
      // eslint-disable-next-line
    } catch (error: any) {
      console.error(
        'Error deactivating subscription plan:',
        error.response || error.message
      );
      alert('Error deactivating subscription plan');
    }
  };

  const activatePlan = async (id: string) => {
    try {
      await axios.put(`${apiRoute}/subscription-plan/activate/${id}`);
      alert('Subscription plan activated successfully');
      loadPlans();
      // eslint-disable-next-line
    } catch (error: any) {
      console.error(
        'Error activating subscription plan:',
        error.response || error.message
      );
      alert('Error activating subscription plan');
    }
  };

  useEffect(() => {
    loadPlans();
  }, []);

  return (
    <LayoutContainer height={height}>
      {isPlanOpen && (
        <Modal
          loadPlans={loadPlans}
          closeModal={closeModal}
          selectedPlan={selectedPlan}
        />
      )}
      <TopMenu />
      <MainContentWrapper>
        <SuperAdminSidebar company={user} navigate={navigate} />
        <ContentWrapper>
          <NavbarAndContentContainer>
            {loading ? (
              <Spinner />
            ) : (
              <SubscriptionPlans
                activatePlan={activatePlan}
                deactivatePlan={deactivatePlan}
                handleEditPlan={handleEditPlan}
                setIsPlanOpen={setIsPlanOpen}
                handleAddNewPlan={handleAddNewPlan}
                subscriptionPlans={subscriptionPlans}
              />
            )}
          </NavbarAndContentContainer>
        </ContentWrapper>
      </MainContentWrapper>
    </LayoutContainer>
  );
};
