import styled from 'styled-components';
import { colors } from '../../../style-utils/colors';

export const StyledComponentsMainWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  /* max-width: 1032px; */
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 18px;
`;

export const StyledInfoCirclesMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 32px 48px;
  gap: 8px;
  justify-content: space-around;
  background-color: ${colors.white};
  border-radius: 8px;
`;

export const StyledInfoCircleWrapper = styled.div<{ backgroundColor?: string }>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colors.secondary_400};
`;

export const StyledInfoInnerCircle = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: ${colors.white};
`;

export const StyledLatestRegisteredAndTopRisingWrapper = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1.9fr 1.1fr;
  gap: 16px;
`;

export const StyledTopCompaniesComponentMainWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
  background-color: ${colors.white};
  border-radius: 8px;
`;

export const StyledTopCompanyComponentWrapper = styled.div<{
  backgroundColor?: string;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
  gap: 16px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colors.neutral_50};
  border-radius: 8px;
`;

export const StyledCompanyComponentImageAndNameWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 4px;
`;

export const StyledCompanyComponentDateWrapper = styled.div<{
  backgroundColor?: string;
}>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  border-radius: 60px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colors.secondary_400};
`;

export const StyledCompanyComponentImage = styled.img`
  box-sizing: border-box;
  max-height: 24px;
`;

export const StyledLastPaymentAndCompanyOwesWrapper = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;
