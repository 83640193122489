import React, { useState } from 'react';
import { DefaultComponentProps } from '../../../../app-router/component-props';
import { StyledTabsMainWrapper } from '../../../settings/components/tabs/tabs-main.styled';
import AdminSettingsTabSelector from './admin-tab-selector';
import CompanyDetails from './company-details';
import Payment from './payment';
import SubscriptionTab from './subscription-plan';

const AdminSettingsTabs: React.FC<DefaultComponentProps> = ({
  user,
  cookies,
}) => {
  const [activeTabId, setActiveTabId] = useState<number>(2);

  const handleTabChange = (tabId: number) => {
    setActiveTabId(tabId);
  };

  const tabs = [
    <CompanyDetails key={0} user={user} cookies={cookies} />,
    <Payment key={1} user={user} cookies={cookies} />,
    <SubscriptionTab key={2} user={user} cookies={cookies} />,
  ];

  return (
    <StyledTabsMainWrapper>
      <AdminSettingsTabSelector
        onTabChange={handleTabChange}
        activeTabId={activeTabId}
      />
      {tabs[activeTabId]}
    </StyledTabsMainWrapper>
  );
};

export default AdminSettingsTabs;
