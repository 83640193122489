import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ChevronDownIcon } from '../../../icons/chevron-down-icon';

export const DropdownContainer = styled.div`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Shadow-0, #fdfdfd);
  position: relative;
`;

export const ButtonContainer = styled.button`
  background-color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  gap: 8px;
`;

export const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;
  background-color: white;
  border: 1px solid #ccc;
  &:hover {
    background-color: #f0f0f0;
  }
`;

export const DropdownList = styled.div`
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10002;
  min-width: 120px;
  text-align: center;
`;

interface DropdownProps {
  onEdit: () => void;
  onDeactivate: () => void;
  onActivate: () => void;
  onClick: () => void;
  planStatus: string;
}

export const Dropdown: React.FC<DropdownProps> = ({
  onEdit,
  onDeactivate,
  onActivate,
  onClick,
  planStatus,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleEdit = () => {
    setIsOpen(false);
    onEdit();
  };

  const handleStatusToggle = () => {
    setIsOpen(false);
    if (planStatus === 'active') {
      onDeactivate();
    } else {
      onActivate();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <DropdownContainer ref={dropdownRef}>
      <ButtonContainer onClick={() => setIsOpen(!isOpen)}>
        <div>Actions</div>
        <ChevronDownIcon />
      </ButtonContainer>
      {isOpen && (
        <DropdownList>
          <DropdownItem
            onClick={() => {
              onClick();
              handleEdit();
            }}
          >
            Edit
          </DropdownItem>
          <DropdownItem onClick={handleStatusToggle}>
            {planStatus === 'active' ? 'Deactivate' : 'Activate'}
          </DropdownItem>
        </DropdownList>
      )}
    </DropdownContainer>
  );
};
