import React from 'react';
import {
  ButtonTitle,
  InputContainer,
  Label,
  StyledInput,
  SignUpFormInputs,
  BlueButton,
} from '../login/login.styled';
import { SignUpData } from '../hooks/use-auth-forms';
import { NavigateFunction } from 'react-router-dom';
import { ErrorLabel, StyledSelectMenu } from '../pages/jobs/jobs.styled';
import { colors } from '../../style-utils/colors';
import styled from 'styled-components';
import { StepHeader } from './step-header';
import Step4 from './step-4';
import { SelectDate } from '../pages/applicants/candidates/date-picker';
import { TextEditor } from '../styled-quill/text-editor';
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 32px;
`;

interface LoginInputProps {
  signUpData: SignUpData;
  handleSignUpChange: (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  setSignUpData: React.Dispatch<React.SetStateAction<SignUpData>>;
  navigate?: NavigateFunction | undefined;
  registerCompany: () => Promise<unknown>;
  isLoading: boolean;
  refreshData: () => void;
  handleNext: () => void;
  handlePrevious: () => void;
  handleRegister: () => void;
  inputRefs: InputRefs;
  currentStep: number;
  totalSteps: number;
  invalidFieldsInputArray: string[];
  handleSignUpFoundedDateChange: (date: Date | null) => void;
  errorMessage: string | null;
}

interface InputRefs {
  name: React.MutableRefObject<HTMLLabelElement | null>;
  website: React.MutableRefObject<HTMLLabelElement | null>;
  industry: React.MutableRefObject<HTMLLabelElement | null>;
  founded: React.MutableRefObject<HTMLLabelElement | null>;
  email: React.MutableRefObject<HTMLLabelElement | null>;
  phoneNumber: React.MutableRefObject<HTMLLabelElement | null>;
  address: React.MutableRefObject<HTMLLabelElement | null>;
  country: React.MutableRefObject<HTMLLabelElement | null>;
  taxNumber: React.MutableRefObject<HTMLLabelElement | null>;
  employeeCount: React.MutableRefObject<HTMLLabelElement | null>;
  description: React.MutableRefObject<HTMLLabelElement | null>;
}

const Step1: React.FC<
  Partial<LoginInputProps> & {
    inputRefs: InputRefs;
    currentStep: number;
    totalSteps: number;
    invalidInputsArray: string[];
    handleSignUpFoundedDateChange: (date: Date | null) => void;
  }
> = ({
  signUpData,
  handleSignUpChange,
  inputRefs,
  currentStep,
  totalSteps,
  invalidInputsArray,
  handleSignUpFoundedDateChange,
}) => {
  const foundedDate = signUpData?.founded ? new Date(signUpData.founded) : '';

  return (
    <>
      <StepHeader
        currentStep={currentStep}
        totalSteps={totalSteps}
        title="Create an account"
      />
      <InputContainer>
        <Label ref={inputRefs.name}>Company name</Label>
        <StyledInput
          bgColor={colors.neutral_50}
          type="text"
          name="name"
          value={signUpData?.name}
          placeholder="Enter text here"
          onChange={handleSignUpChange}
        />
        {invalidInputsArray.includes('name') && (
          <ErrorLabel>Company name is required</ErrorLabel>
        )}
      </InputContainer>
      <InputContainer>
        <Label ref={inputRefs.website}>Website</Label>
        <StyledInput
          bgColor={colors.neutral_50}
          type="text"
          name="website"
          value={signUpData?.website}
          placeholder="Enter text here"
          onChange={handleSignUpChange}
        />
        {invalidInputsArray.includes('website') && (
          <ErrorLabel>Website is required</ErrorLabel>
        )}
      </InputContainer>
      <InputContainer>
        <Label ref={inputRefs.industry}>Industry</Label>
        <StyledInput
          bgColor={colors.neutral_50}
          type="text"
          name="industry"
          value={signUpData?.industry}
          placeholder="Enter text here"
          onChange={handleSignUpChange}
        />
        {invalidInputsArray.includes('industry') && (
          <ErrorLabel>Industry is required</ErrorLabel>
        )}
      </InputContainer>
      <InputContainer>
        <Label ref={inputRefs.founded}>Founded</Label>

        <SelectDate
          onChange={handleSignUpFoundedDateChange}
          defaultValue={new Date(foundedDate)}
          style={{
            width: '100%',
            borderRadius: '8px',
            borderStyle: 'none',
            height: '48px',
            padding: '0 12px',
            backgroundColor: colors.neutral_50,
          }}
        />
        {invalidInputsArray.includes('founded') && (
          <ErrorLabel>Founded date is required</ErrorLabel>
        )}
      </InputContainer>
    </>
  );
};

const Step2: React.FC<
  Partial<LoginInputProps> & {
    inputRefs: InputRefs;
    currentStep: number;
    totalSteps: number;
    invalidInputsArray: string[];
  }
> = ({
  signUpData,
  handleSignUpChange,
  inputRefs,
  currentStep,
  totalSteps,
  invalidInputsArray,
}) => (
  <>
    <StepHeader
      currentStep={currentStep}
      totalSteps={totalSteps}
      title="Contact Info"
    />
    <InputContainer>
      <Label ref={inputRefs.email}>Email address</Label>
      <StyledInput
        bgColor={colors.neutral_50}
        type="text"
        name="email"
        value={signUpData?.email}
        placeholder="Enter text here"
        onChange={handleSignUpChange}
      />
      {invalidInputsArray.includes('email') && (
        <ErrorLabel>Email is required</ErrorLabel>
      )}
    </InputContainer>
    <InputContainer>
      <Label ref={inputRefs.phoneNumber}>Phone Number</Label>
      <StyledInput
        bgColor={colors.neutral_50}
        type="text"
        name="phoneNumber"
        value={signUpData?.phoneNumber}
        placeholder="Enter text here"
        onChange={handleSignUpChange}
      />
      {invalidInputsArray.includes('phoneNumber') && (
        <ErrorLabel>Phone number is required</ErrorLabel>
      )}
    </InputContainer>
    <InputContainer>
      <Label ref={inputRefs.address}>Address</Label>
      <StyledInput
        bgColor={colors.neutral_50}
        type="text"
        name="address"
        value={signUpData?.address}
        placeholder="Enter text here"
        onChange={handleSignUpChange}
      />
      {invalidInputsArray.includes('address') && (
        <ErrorLabel>Address is required</ErrorLabel>
      )}
    </InputContainer>
    <InputContainer>
      <Label ref={inputRefs.country}>Country</Label>
      <StyledInput
        bgColor={colors.neutral_50}
        type="text"
        name="country"
        value={signUpData?.country}
        placeholder="Enter text here"
        onChange={handleSignUpChange}
      />
      {invalidInputsArray.includes('country') && (
        <ErrorLabel>Country is required</ErrorLabel>
      )}
    </InputContainer>
    <InputContainer>
      <Label ref={inputRefs.taxNumber}>Tax Number</Label>
      <StyledInput
        bgColor={colors.neutral_50}
        type="text"
        name="taxNumber"
        value={signUpData?.taxNumber}
        placeholder="Enter text here"
        onChange={handleSignUpChange}
      />
      {invalidInputsArray.includes('taxNumber') && (
        <ErrorLabel>Tax number is required</ErrorLabel>
      )}
    </InputContainer>
  </>
);
const Step3: React.FC<
  Partial<LoginInputProps> & {
    inputRefs: InputRefs;
    currentStep: number;
    totalSteps: number;
    invalidInputsArray: string[];
    setSignUpData: React.Dispatch<React.SetStateAction<SignUpData>>; // Ensure this prop is required
  }
> = ({
  signUpData,
  handleSignUpChange,
  currentStep,
  totalSteps,
  invalidInputsArray,
  inputRefs,
  setSignUpData,
}) => {
  const selectFields = [
    '1 - 10 employees',
    '11 - 50 employees',
    '51 - 100 employees',
    '101 - 500 employees',
    '501 - 1,000 employees',
    '1,001 - 5,000 employees',
    '5,001 - 10,000 employees',
    '10,001 + employees',
  ];

  const handleTextEditorChange = (value: string) => {
    setSignUpData((prevState: SignUpData) => ({
      ...prevState,
      description: value,
    }));
  };

  return (
    <>
      <StepHeader
        currentStep={currentStep}
        totalSteps={totalSteps}
        title="About Company"
      />
      <InputContainer>
        <Label ref={inputRefs.employeeCount}>Employee Count</Label>
        <StyledSelectMenu
          name="employeeCount"
          defaultValue=""
          onChange={handleSignUpChange}
        >
          <option value="" disabled>
            Select an employee count
          </option>
          {selectFields.map((field, index) => (
            <option key={index} value={field}>
              {field}
            </option>
          ))}
        </StyledSelectMenu>
        {invalidInputsArray.includes('employeeCount') && (
          <ErrorLabel>Employee Count is required</ErrorLabel>
        )}
      </InputContainer>
      <InputContainer>
        <Label ref={inputRefs.description}>Description</Label>
        <TextEditor
          backgroundColor={colors.neutral_50}
          changeHandler={handleTextEditorChange}
          defaultValue={signUpData?.description || ''}
        />
        {invalidInputsArray.includes('description') && (
          <ErrorLabel>Description is required</ErrorLabel>
        )}
      </InputContainer>
    </>
  );
};

export const RegisterInputsCompany: React.FC<LoginInputProps> = ({
  setSignUpData,
  signUpData,
  handleSignUpChange,
  handleSignUpFoundedDateChange,
  handleNext,
  handlePrevious,
  handleRegister,
  currentStep,
  inputRefs,
  totalSteps,
  invalidFieldsInputArray,
  errorMessage,
}) => {
  return (
    <SignUpFormInputs>
      {currentStep === 1 && (
        <Step1
          signUpData={signUpData}
          handleSignUpChange={handleSignUpChange}
          inputRefs={inputRefs}
          currentStep={currentStep}
          totalSteps={totalSteps}
          invalidInputsArray={invalidFieldsInputArray}
          handleSignUpFoundedDateChange={handleSignUpFoundedDateChange}
        />
      )}
      {currentStep === 2 && (
        <Step2
          signUpData={signUpData}
          handleSignUpChange={handleSignUpChange}
          inputRefs={inputRefs}
          currentStep={currentStep}
          totalSteps={totalSteps}
          invalidInputsArray={invalidFieldsInputArray}
        />
      )}
      {currentStep === 3 && (
        <Step3
          signUpData={signUpData}
          handleSignUpChange={handleSignUpChange}
          inputRefs={inputRefs}
          currentStep={currentStep}
          totalSteps={totalSteps}
          invalidInputsArray={invalidFieldsInputArray}
          setSignUpData={setSignUpData} // Ensure this prop is passed for the custom handler
        />
      )}
      {currentStep === 4 && (
        <Step4
          errorMessage={errorMessage}
          signUpData={signUpData}
          setSignUpData={setSignUpData}
          currentStep={currentStep}
          totalSteps={totalSteps}
          handlePrevious={handlePrevious}
          handleRegister={handleRegister}
        />
      )}
      <ButtonsContainer>
        {currentStep > 1 && currentStep !== 4 && (
          <BlueButton type="button" onClick={handlePrevious}>
            <ButtonTitle fontColor="white">Previous</ButtonTitle>
          </BlueButton>
        )}
        {currentStep < 4 ? (
          <BlueButton type="button" onClick={handleNext}>
            <ButtonTitle fontColor="white">Next</ButtonTitle>
          </BlueButton>
        ) : null}
      </ButtonsContainer>
    </SignUpFormInputs>
  );
};
