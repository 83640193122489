import styled from 'styled-components';
import { colors } from '../../../../../../../../style-utils/colors';

export const StyledUserSettingsMainWrapper = styled.div`
  box-sizing: border-box;
  padding: 40px;
  background-color: ${colors.base_white};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const StyledChangePasswordWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: 500px;
  align-items: center;
  justify-content: center;
`;
