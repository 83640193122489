import React from 'react';

export const LinkedinIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.00016 6H1.3335V14H4.00016V6Z"
        stroke="#5E5A57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6665 5.33325C11.7274 5.33325 12.7448 5.75468 13.4949 6.50482C14.2451 7.25497 14.6665 8.27239 14.6665 9.33325V13.9999H11.9998V9.33325C11.9998 8.97963 11.8594 8.64049 11.6093 8.39044C11.3593 8.14039 11.0201 7.99992 10.6665 7.99992C10.3129 7.99992 9.97374 8.14039 9.7237 8.39044C9.47365 8.64049 9.33317 8.97963 9.33317 9.33325V13.9999H6.6665V9.33325C6.6665 8.27239 7.08793 7.25497 7.83808 6.50482C8.58822 5.75468 9.60564 5.33325 10.6665 5.33325V5.33325Z"
        stroke="#5E5A57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66683 3.99992C3.40321 3.99992 4.00016 3.40296 4.00016 2.66659C4.00016 1.93021 3.40321 1.33325 2.66683 1.33325C1.93045 1.33325 1.3335 1.93021 1.3335 2.66659C1.3335 3.40296 1.93045 3.99992 2.66683 3.99992Z"
        stroke="#5E5A57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
