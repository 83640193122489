// useAuthForms.js
import { useState } from 'react';

export interface SignUpData {
  name: string;
  description: string;
  email: string;
  website: string;
  phoneNumber: string;
  industry: string;
  founded: string;
  address: string;
  country: string;
  locations: string;
  taxNumber: string;
  employeeCount: number;
  specialities: string;
  paymentPlan: string;
  password?: string;
}

interface LogInData {
  email: string;
  password: string;
}

export const useAuthForms = () => {
  const [logInData, setLogInData] = useState<LogInData>({
    email: '',
    password: '',
  });

  const [signUpData, setSignUpData] = useState<SignUpData>({
    name: '',
    description: '',
    email: '',
    website: '',
    phoneNumber: '',
    industry: '',
    founded: '',
    address: '',
    country: '',
    locations: '',
    taxNumber: '',
    employeeCount: 0,
    specialities: '',
    paymentPlan: '',
    password: '',
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const trimmedValue = name === 'password' ? value.trim() : value;
    setLogInData({ ...logInData, [name]: trimmedValue });
  };

  const handleSignUpChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    console.log(name, value);

    if (name === 'taxNumber') {
      const regex = /^[0-9-/]+$/;

      if (regex.test(value) || value === '') {
        setSignUpData((prevState) => ({ ...prevState, [name]: value }));
      }
    } else if (name === 'phoneNumber') {
      const regex = /^[0-9+]*$/;

      console.log('Updating phoneNumber with value:', value);

      if (regex.test(value) || value === '') {
        setSignUpData((prevState) => ({ ...prevState, [name]: value }));
      }
    } else {
      setSignUpData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleSignUpFoundedDateChange = (date: Date | null) => {
    if (date) {
      const formattedDate = formatDate(date);
      setSignUpData((prevState) => ({ ...prevState, founded: formattedDate }));
    }
  };

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const refreshData = () => {
    setSignUpData({
      name: '',
      description: '',
      email: '',
      website: '',
      phoneNumber: '',
      industry: '',
      founded: '',
      address: '',
      country: '',
      locations: '',
      taxNumber: '',
      employeeCount: 0,
      specialities: '',
      paymentPlan: '',
      password: '',
    });
    setLogInData({
      email: '',
      password: '',
    });
  };

  return {
    refreshData,
    logInData,
    setLogInData,
    signUpData,
    setSignUpData,
    handleInputChange,
    handleSignUpChange,
    handleSignUpFoundedDateChange,
  };
};
