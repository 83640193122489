// EmployeesTimeoff.tsx
import React from 'react';
import { DefaultComponentProps } from '../../../app-router/component-props';
import { useWindowHeight } from '../../../../style-utils/use-window-height';
import {
  ContentWrapper,
  LayoutContainer,
  MainContentWrapper,
  NavbarAndContentTransparentContainer,
} from '../../../layout/layout-container.styled';
import { TopMenu } from '../../../layout/top-menu/top-menu';
import { Sidebar } from '../../../layout/sidebar/sidebar';
import {
  StyledEmployeesTimeoffMainWrapper,
  StyledEmployeesTimeoffSearchAndFilterWrapper,
  StyledEmployeesTimeoffTitleAndSearchWrapper,
} from './employees-timeoff.styled';
import { Text, font } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';
import TimeOffEmployeesSearch from './components/search/search';
import TimeOffEmployeesFilter from './components/filter/filter';
import TimeOffEmployeesTable from './components/table/table';
import { useTimeOff, TimeOffProvider } from './context/timeoff-context';
import Pagination from './components/pagination/pagination';
import { Chat } from '../../../chat/chat';

const EmployeesTimeoffContent: React.FC = () => {
  const { currentPage, totalPages, setPage } = useTimeOff();

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  return (
    <StyledEmployeesTimeoffMainWrapper>
      <StyledEmployeesTimeoffTitleAndSearchWrapper>
        <Text
          fontFamily={font.family}
          fontSize={38}
          fontWeight={700}
          color={colors.base_black}
          margin="0"
        >
          Day Off
        </Text>
        <StyledEmployeesTimeoffSearchAndFilterWrapper>
          <TimeOffEmployeesSearch />
          <TimeOffEmployeesFilter />
        </StyledEmployeesTimeoffSearchAndFilterWrapper>
      </StyledEmployeesTimeoffTitleAndSearchWrapper>
      <TimeOffEmployeesTable />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </StyledEmployeesTimeoffMainWrapper>
  );
};

const EmployeesTimeoff: React.FC<DefaultComponentProps> = ({
  user,
  navigate,
}) => {
  const height = useWindowHeight();

  return (
    <LayoutContainer height={height}>
      <TopMenu />
      <MainContentWrapper>
        <Sidebar company={user} selectedOption={''} navigate={navigate} />
        <ContentWrapper>
          <NavbarAndContentTransparentContainer>
            <TimeOffProvider user={user}>
              <EmployeesTimeoffContent />
            </TimeOffProvider>
          </NavbarAndContentTransparentContainer>
        </ContentWrapper>
      </MainContentWrapper>
      <Chat />
    </LayoutContainer>
  );
};

export default EmployeesTimeoff;
