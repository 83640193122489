import React, { useEffect, useState } from 'react';
import { Applicant } from '../onboarding/onboarding-table';
import { fetchItemById } from '../../../requests/requests';
import { JobOfferData } from '../../../../interfaces/job-interfaces';
import { Text, font } from '../../../../style-utils/text';
import styled from 'styled-components';
import { colors } from '../../../../style-utils/colors';
import { BackContainer } from '../../employees/employee.styled';
import { LeftArrow } from '../../../icons/left-arrow';
import { EnvelopeIcon } from '../../../icons/envelope';
import { PhoneIcon } from '../../../icons/phone';
import { ButtonContainer } from '../../../login/login.styled';

const keywords = [
  'Key Skills',
  'IT Experience',
  'Latest Company',
  'Interview Questions',
  'Key Strengths',
  'Weaknesses',
  'Career Progression',
  'Educational Background',
  'Achievements',
  'Key Skills:',
  'IT Experience:',
  'Latest Company:',
  'Interview Questions:',
  'Key Strengths:',
  'Weaknesses:',
  'Career Progression:',
  'Educational Background:',
  'Achievements:',
];

interface AiAnalysisProps {
  applicant?: Applicant;
  toggleAnalysis: () => void;
}

export interface ParsedAiAnalysisData {
  percentage_match: number;
  profile_match: string;
  it_experience: string;
  key_skills: string[];
  past_experience: string[];
  key_strengths: string[];
  weaknesses: string[];
  career_progression: string;
  educational_background: string[];
  achievements: string;
  interview_questions: string[];
}

export const AIContainer = styled.div`
  background-color: ${colors.creme_100};
`;

export const Flex = styled.div<{ gap?: string; mb?: string }>`
  display: flex;
  gap: ${({ gap }) => gap && `${gap};`};
  margin-bottom: ${({ mb }) => (mb ? `${mb}` : `10px;`)};
`;

export const AIInfo = styled.div<{ isPreline?: boolean }>`
  box-sizing: border-box;
  padding: 24px;
  border-radius: 8px;
  background-color: ${colors.base_white};
  margin-bottom: 10px;
  ${({ isPreline }) => isPreline && `white-space: pre-line;`};
`;

export const AITitle = styled.h3`
  font-family: ${font.family};
  color: ${colors.base_black};
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 10px;
`;

export const AIUlList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  font-size: 14px;
  font-family: ${font.family};
  color: ${colors.base_black};
`;

export const AIList = styled.li`
  margin-bottom: 5px;
`;

export function isValidJson(jsonString: string): boolean {
  try {
    const parsed = JSON.parse(jsonString);

    return typeof parsed === 'object' && parsed !== null;
  } catch (e) {
    return false;
  }
}

export const getAiData = (inputString: string) => {
  const sections = inputString
    ?.split('///')
    .map((section) => section.trim())
    .filter((section) => section); // This removes empty strings

  return sections;
};

export const extractPercentage = (text: string) => {
  const percentageRegex = /(\d{1,3})%/;
  const match = text.match(percentageRegex);

  if (match) {
    return `${match[1]}%`; // Return the percentage value as a string
  } else {
    return 'No percentage found';
  }
};

export const delimiterFix = (text?: string) => {
  // console.log('ai_info =', text);
  let newDataWithoutDelimiter;
  if (text) {
    // newDataWithoutDelimiter = text.replace(/\/{3,4}/g, '');
    newDataWithoutDelimiter = text.replace(/:|\/{3,4}/g, '');
  }
  // console.log('removed delimiters', newDataWithoutDelimiter);
  const newDataWithDelimiter = keywords.reduce((result, keyword) => {
    const index = result?.indexOf(keyword);
    if (index !== -1) {
      return `${result?.slice(0, index)} ///${result?.slice(index)}`;
    }

    return result;
  }, newDataWithoutDelimiter);
  console.log('added delimiter', newDataWithDelimiter);

  return newDataWithDelimiter;
};

export const AiAnalysis: React.FC<AiAnalysisProps> = ({
  applicant,
  toggleAnalysis,
}) => {
  // console.log('full-name = ', applicant?.first_name, applicant?.last_name);
  // console.log('ai_info = ', applicant?.ai_info);
  // console.log('job-id = ', applicant?.job_id);
  const [jobApplied, setJobApplied] = useState<JobOfferData>();
  const [parsedData, setParsedData] = useState<
    ParsedAiAnalysisData | string[] | null
  >(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedApplicant, setSelectedApplicant] = useState<Applicant | null>(
    null
  );

  useEffect(() => {
    const getJob = async () => {
      if (applicant && applicant.job_id) {
        const job = await fetchItemById('jobs', applicant?.job_id);
        setJobApplied(job as JobOfferData);
      }
    };
    getJob();
  }, []);

  useEffect(() => {
    if (applicant?.ai_info) {
      const inputString = applicant.ai_info;

      // Check if the input string is valid JSON
      if (isValidJson(inputString)) {
        try {
          const jsonObject = JSON.parse(inputString);
          setParsedData(jsonObject);
          // setError(null);
        } catch (e) {
          // setError('Error parsing JSON data');
          setParsedData(null);
        }
      } else {
        // Handle as the old data format
        const fixedArray = delimiterFix(inputString);
        if (fixedArray) {
          const textArray = getAiData(fixedArray);
          setParsedData(textArray);
        }
        // setError(null);
      }
    } else {
      setParsedData(null);
      // setError(null);
    }
  }, [applicant?.ai_info]);

  console.log(parsedData);

  return (
    <>
      <AIContainer>
        <BackContainer onClick={() => toggleAnalysis()}>
          <LeftArrow />
          <Text
            display="inline-block"
            color={colors.neutral_400}
            mt={0}
            mb={0}
            fontFamily={font.family}
          >
            Back to Candidates
          </Text>
        </BackContainer>
        {Array.isArray(parsedData) ? (
          <AIInfo>
            <Text mt={0} mb={10} fontSize={36} fontFamily={font.family}>
              {applicant?.first_name}&nbsp;{applicant?.last_name}&nbsp;
              {parsedData && extractPercentage(parsedData[0])}
            </Text>
            <Flex>
              <Text
                mt={0}
                mb={8}
                fontSize={16}
                fontFamily={font.family}
                fontWeight={700}
              >
                Job Applied For:&nbsp;
              </Text>
              <Text
                mt={0}
                mb={0}
                fontSize={16}
                fontFamily={font.family}
                dangerouslySetInnerHTML={{
                  __html: jobApplied ? jobApplied.job_position : '',
                }}
              ></Text>
            </Flex>
            <Flex>
              <Text
                mt={0}
                mb={8}
                fontSize={16}
                fontFamily={font.family}
                fontWeight={700}
              >
                Description:&nbsp;
              </Text>
              <Text
                display="block"
                mt={0}
                mb={0}
                fontSize={16}
                fontFamily={font.family}
                dangerouslySetInnerHTML={{
                  __html: jobApplied ? jobApplied?.job_description : '',
                }}
              />
            </Flex>
            <Flex>
              <EnvelopeIcon />
              <Text
                mt={0}
                mb={0}
                ml={10}
                fontSize={16}
                fontFamily={font.family}
              >
                {applicant?.email}
              </Text>
            </Flex>
            <Flex>
              <PhoneIcon />
              <Text
                mt={0}
                mb={0}
                ml={10}
                fontSize={16}
                fontFamily={font.family}
              >
                {applicant?.phone_number}
              </Text>
            </Flex>
          </AIInfo>
        ) : (
          <AIInfo>
            <Text mt={0} mb={10} fontSize={36} fontFamily={font.family}>
              {applicant?.first_name}&nbsp;{applicant?.last_name}&nbsp;
              {parsedData?.percentage_match}
            </Text>
            <Flex>
              <Text
                mt={0}
                mb={8}
                fontSize={16}
                fontFamily={font.family}
                fontWeight={700}
              >
                Job Applied For:&nbsp;
              </Text>
              <Text
                display="block"
                mt={0}
                mb={0}
                fontSize={16}
                fontFamily={font.family}
                dangerouslySetInnerHTML={{
                  __html: jobApplied ? jobApplied.job_position : '',
                }}
              />
            </Flex>
            <Flex>
              <Text
                mt={0}
                mb={8}
                fontSize={16}
                fontFamily={font.family}
                fontWeight={700}
              >
                Description:&nbsp;
              </Text>
              <Text
                display="block"
                mt={0}
                mb={0}
                fontSize={16}
                fontFamily={font.family}
                dangerouslySetInnerHTML={{
                  __html: jobApplied ? jobApplied?.job_description : '',
                }}
              />
            </Flex>
            <Flex>
              <EnvelopeIcon />
              <Text
                mt={0}
                mb={0}
                ml={10}
                fontSize={16}
                fontFamily={font.family}
              >
                {applicant?.email}
              </Text>
            </Flex>
            <Flex>
              <PhoneIcon />
              <Text
                mt={0}
                mb={0}
                ml={10}
                fontSize={16}
                fontFamily={font.family}
              >
                {applicant?.phone_number}
              </Text>
            </Flex>
          </AIInfo>
        )}
        {Array.isArray(parsedData) ? (
          parsedData?.map((text, i) => {
            return (
              <AIInfo key={i} isPreline>
                <Text fontWeight={300} mt={0} mb={0} fontFamily={font.family}>
                  {text}
                </Text>
              </AIInfo>
            );
          })
        ) : (
          <AIInfo isPreline>
            <Text fontWeight={300} mt={0} mb={0} fontFamily={font.family}>
              {parsedData?.profile_match}
            </Text>
          </AIInfo>
        )}
        <Flex>
          <ButtonContainer
            style={{ marginLeft: 'auto' }}
            mt={1}
            backgroundColor={colors.primary_500}
            onClick={() => toggleAnalysis()}
          >
            <Text mt={0} mb={0} fontWeight={700} fontFamily={font.family}>
              Back to Candidates
            </Text>
          </ButtonContainer>
        </Flex>
      </AIContainer>
    </>
  );
};
