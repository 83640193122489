import React from 'react';
import {
  ContentWrapper,
  LayoutContainer,
  NavbarAndContentContainer,
  MainContentWrapper,
} from '../../../layout/layout-container.styled';
import { useWindowHeight } from '../../../../style-utils/use-window-height';
import { TopMenu } from '../../../layout/top-menu/top-menu';
import { NavigateFunction } from 'react-router-dom';
import { Cookie } from 'universal-cookie';
import { SuperAdminSidebar } from '../../../layout/sidebar/super-admin-sidebar';
import { SuperAdminCompanies } from './companies';

interface CompaniesMain {
  navigate: NavigateFunction;
  // eslint-disable-next-line
  user: any;
  cookies?: Cookie;
  viewAnalysis?: boolean;
  toggleAnalysis?: () => void;
}

export const CompaniesMain: React.FC<CompaniesMain> = ({ navigate, user }) => {
  const height = useWindowHeight();

  return (
    <LayoutContainer height={height}>
      <TopMenu />
      <MainContentWrapper>
        <SuperAdminSidebar company={user} navigate={navigate} />
        <ContentWrapper>
          <NavbarAndContentContainer>
            <SuperAdminCompanies />
          </NavbarAndContentContainer>
        </ContentWrapper>
      </MainContentWrapper>
    </LayoutContainer>
  );
};
