// payment-plan.tsx
import React from 'react';
import styled from 'styled-components';
import { font } from '../../style-utils/text';
import { SignUpData } from '../hooks/use-auth-forms';
import { Button } from '../layout/design-helpers';
import { InputContainer, Label } from '../login/login.styled';
import { colors } from '../../style-utils/colors';
import './payment-plan.css';

export const plans = [
  {
    name: 'Starter',
    price: '$49',
    frequency: '/ month',
    description: `<ul class="price-card-content">
      <li class="checked">7 days FREE trial</li>
      <li class="checked">Job posting and application tracking</li>
      <li class="unchecked">AI-driven job posting creation</li>
      <li class="checked">Basic candidate management</li>
      <li class="checked">Basic analytics and reporting</li>
      <li class="unchecked">Advanced analytics and reporting</li>
      <li class="unchecked">Automated candidate sourcing</li>
      <li class="unchecked">AI-driven candidate matching</li>
      <li class="unchecked">Custom AI algorithms</li>
      <li class="unchecked">Integration with other HR systems (ATS, payroll, etc,)</li>
      <li class="checked">Email support</li>
      <li class="unchecked">Priority email support</li>
      <li class="unchecked">24/7 priority support</li>
      <li class="unchecked">Dedicated account manager</li>
      <li class="checked">User limit: Up to 2 Users</li>
      <li class="unchecked">API</li>
      <li class="unchecked">Slack/Teams Integration</li>
      <li class="checked">PRICE</li>
      <li class="checked">Annual price discount -10%</li>
    </ul>`,
    canPurchase: true,
  },
  {
    name: 'Pro',
    price: '$99',
    frequency: '/ month',
    description: `<ul class="price-card-content">
      <li class="checked">7 days FREE trial</li>
      <li class="checked">Job posting and application tracking</li>
      <li class="checked">AI-driven job posting creation</li>
      <li class="checked">Basic candidate management</li>
      <li class="checked">Basic analytics and reporting</li>
      <li class="checked">Advanced analytics and reporting</li>
      <li class="checked">Automated candidate sourcing</li>
      <li class="checked">AI-driven candidate matching</li>
      <li class="unchecked">Custom AI algorithms</li>
      <li class="unchecked">Integration with other HR systems (ATS, payroll, etc,)</li>
      <li class="checked">Email support</li>
      <li class="checked">Priority email support</li>
      <li class="unchecked">24/7 priority support</li>
      <li class="unchecked">Dedicated account manager</li>
      <li class="checked">User limit: Up to 10 Users</li>
      <li class="unchecked">API</li>
      <li class="checked">Slack/Teams Integration</li>
      <li class="checked">PRICE</li>
      <li class="checked">Annual price discount -15%</li>
    </ul>`,
    canPurchase: true,
  },
  {
    name: 'Enterprise',
    price: '$399',
    frequency: '/ month',
    description: `<ul class="price-card-content">
      <li class="checked">7 days FREE trial</li>
      <li class="checked">Job posting and application tracking</li>
      <li class="checked">AI-driven job posting creation</li>
      <li class="checked">Basic candidate management</li>
      <li class="checked">Basic analytics and reporting</li>
      <li class="checked">Advanced analytics and reporting</li>
      <li class="checked">Automated candidate sourcing</li>
      <li class="checked">AI-driven candidate matching</li>
      <li class="checked">Custom AI algorithms</li>
      <li class="checked">Integration with other HR systems (ATS, payroll, etc,)</li>
      <li class="checked">Email support</li>
      <li class="checked">Priority email support</li>
      <li class="checked">24/7 priority support</li>
      <li class="checked">Dedicated account manager</li>
      <li class="checked">User limit</li>
      <li class="checked">API</li>
      <li class="checked">Slack/Teams Integration</li>
      <li class="checked">PRICE</li>
      <li class="checked">Annual price discount -20%</li>
    </ul>`,
    canPurchase: true,
  },
  {
    name: 'Custom',
    price: '',
    frequency: '',
    description: `<ul class="price-card-content">
      <li style={{display:'none'}}></li>
    </ul>
    <div>Our comprehensive package for large corporations seeking the full power of Work Wise’s capabilities, including priority support and customization options.</div>`,
    canPurchase: true,
  },
];

const PaymentPlansContainer = styled.div<{ bg?: string; borderColor?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid ${({ borderColor }) => `${borderColor}`};
  border-radius: 8px;
  flex-basis: 200px;
  max-width: 100%;
  overflow: auto;
  background: ${({ bg }) => (bg ? `${bg}` : 'white')};
  cursor: pointer;
`;

const TextContainer = styled.div<{ color?: string }>`
  color: ${({ color }) => color && color};
  overflow: auto;
  max-height: 160px;

  /* Hide scrollbar for webkit browsers */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const PaymentPlansText = styled.p<{ paddingLeft?: number; fontSize?: number }>`
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px;` : '12px')};
  font-family: ${font.family};
  padding: 8px;
  ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft}px;`}
  margin: 0;
`;

const PaymentPlansTextContainer = styled.div<{ paddingBottom?: number }>`
  display: flex;
  flex: 1;
  ${({ paddingBottom }) => paddingBottom && `margin-bottom: ${paddingBottom};`}
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  gap: 8px;
`;

interface PaymentPlansProps {
  setSignUpData: React.Dispatch<React.SetStateAction<SignUpData>>;
  signUpData: SignUpData;
  setSelectedPlan?: (planId: string, amount: number) => void;
}

export const PaymentPlans: React.FC<PaymentPlansProps> = ({
  setSignUpData,
  signUpData,
  setSelectedPlan,
}) => {
  const localColors = [
    colors.primary_50,
    colors.primary_100,
    colors.primary_300,
    colors.primary_500,
  ];

  const handlePlanSelect = (planId: string, amount: number) => {
    setSignUpData({
      ...signUpData,
      paymentPlan: planId,
    });
    setSelectedPlan && setSelectedPlan(planId, amount);
  };

  return (
    <>
      <InputContainer>
        <Label>Subscription Plans</Label>
      </InputContainer>
      <Container>
        {plans.map((e, i) => {
          const amount = parseFloat(e.price.replace('$', '')) * 100; // Convert price to cents

          return (
            <PaymentPlansContainer
              borderColor={localColors[i % localColors.length]}
              onClick={() => handlePlanSelect(e.name, amount)}
              key={i}
              bg={
                signUpData.paymentPlan === e.name
                  ? `linear-gradient(to top, ${colors.primary_100}, ${colors.primary_800})`
                  : 'white'
              }
            >
              <TextContainer
                color={signUpData.paymentPlan === e.name ? 'white' : 'black'}
              >
                <PaymentPlansTextContainer paddingBottom={16}>
                  <PaymentPlansText paddingLeft={16}>
                    <strong>{e.name}</strong>
                  </PaymentPlansText>
                </PaymentPlansTextContainer>

                <PaymentPlansTextContainer>
                  <PaymentPlansText fontSize={18}>
                    {e.price + ' ' + e.frequency}
                  </PaymentPlansText>
                </PaymentPlansTextContainer>
              </TextContainer>

              <Button
                type="button"
                backgroundColor={
                  signUpData.paymentPlan.toLowerCase() === e.name.toLowerCase()
                    ? `${colors.secondary_700}`
                    : 'white'
                }
                textColor={
                  signUpData.paymentPlan === e.name
                    ? 'white'
                    : `${colors.secondary_700}`
                }
              >
                Select Plan
              </Button>
              <TextContainer>
                <PaymentPlansTextContainer>
                  <PaymentPlansText
                    dangerouslySetInnerHTML={{ __html: e.description }}
                  />
                </PaymentPlansTextContainer>
              </TextContainer>
            </PaymentPlansContainer>
          );
        })}
      </Container>
    </>
  );
};
