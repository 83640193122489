import styled, { css } from 'styled-components';
import { colors } from '../../../style-utils/colors';
import { font } from '../../../style-utils/text';
// import { font } from '../../../style-utils/text';

export const TitleButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
`;

export const ActiveJobOffers = styled.div<{
  isDashboard?: boolean;
}>`
  background: ${colors.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: ${({ isDashboard }) => (isDashboard ? '24px' : '14px 24px')};
`;

export const DraftJobOffers = styled.div`
  padding: 14px 24px;
  background: ${colors.white};
  margin-top: 24px;
  border-radius: 8px;
  gap: 4px;
`;

export const JobOffer = styled.div<{
  margin?: string;
  w?: string;
  h?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 14px 24px;
  background: ${colors.neutral_50};
  border-radius: 8px;
  ${({ margin }) => margin && `margin: ${margin}`};
  // width: ${({ w }) => (w ? w : '96%')}};
  // height: ${({ h }) => (h ? h : '70px')}};
`;

export const JobDetails = styled.div<{
  w?: string;
  h?: string;
}>`
  box-sizing: border-box;
  position: absolute;
  right: 0;
  top: 0;
  padding: 32px;
  background: ${colors.dashboard_light_grey};
  border-radius: 8px;
  width: ${({ w }) => (w ? w : '96%')}};
  height: ${({ h }) => (h ? h : '100%')}};
  overflow-y: auto;
  z-index: 2;

    /* Hide scrollbar for webkit browsers */
    ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const JobOffersParent = styled.div`
  display: flex;
  gap: 8px;
`;

export const JobOffersDetailsContainer = styled.div<{ height?: string }>`
  box-sizing: border-box;
  padding: 32px;
  background: ${colors.dashboard_light_grey};
  border-radius: 8px;
  /* ${({ height }) => height && `height: ${height};`}; */
  min-height: fit-content;
  overflow-y: auto;
  flex: 1;
  position: relative;

  /* Hide scrollbar for webkit browsers */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const JobDetailsInner = styled.div`
  display: flex;
  // justify-content: space-between;
  gap: 10px;
`;

export const JobDetailsContainer = styled.div<{
  height?: boolean;
  overflow?: boolean;
}>`
  ${({ height }) => height && 'height: 300px;'};
  ${({ overflow }) => overflow && 'overflow-y: auto;'};
`;

export const JobNameDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const OfferCount = styled.div<{ backgroundColor?: string }>`
  display: flex;
  justify-content: center;
  width: 60px;
  padding: 4px;
  margin-bottom: 4px;
  background: ${({ backgroundColor }) => backgroundColor && backgroundColor};
  border-radius: 4px;
`;

export const IconsDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const EditContainer = styled.div`
  cursor: pointer;
  margin-left: 24px;
`;

export const DeleteContainer = styled.div`
  cursor: pointer;
  margin-left: 24px;
`;

export const CreateVacancyInput = styled.div<{
  width?: string;
  isBorderBox?: boolean;
  isRelative?: boolean;
}>`
  ${({ isBorderBox }) => isBorderBox && `box-sizing: border-box;`};
  padding: 12px 24px;
  background: ${colors.white};
  margin-top: 16px;
  border-radius: 8px;
  gap: 4px;
  ${({ isRelative }) => isRelative && `position: relative;`};
  width: ${({ width }) => width};

  ${({ isBorderBox }) =>
    isBorderBox &&
    css`
      & > * {
        box-sizing: border-box;
      }
    `}
`;

export const StyledJobInput = styled.input<{
  customSkill?: boolean;
  width?: string;
}>`
  display: flex;
  align-items: center;
  border-radius: 8px;
  width: ${({ width }) => (width ? width : '96%')};
  border: none;
  padding: 14px 16px;
  background: ${colors.neutral_50};
  font-family: ${font.family};
  width: ${({ customSkill }) => customSkill && '200px'};
  margin-top: ${({ customSkill }) => customSkill && '10px'};

  ::placeholder {
    color: ${colors.neutral_700};
  }

  &:hover {
    outline: none;
  }

  &:focus {
    outline: none;
    background: ${colors.dashboard_light_grey};
  }
`;

export const StyledJobTextArea = styled.textarea<{
  customSkill?: boolean;
  width?: string;
}>`
  display: flex;
  align-items: center;
  border-radius: 8px;
  width: ${({ width }) => (width ? width : '96%')};
  border: none;
  padding: 14px 16px;
  background: ${colors.neutral_50};
  font-family: ${font.family};
  font-size: 14px;
  width: ${({ customSkill }) => customSkill && '200px'};
  margin-top: ${({ customSkill }) => customSkill && '10px'};
  height: 120px;
  resize: none;

  ::placeholder {
    color: ${colors.neutral_700};
    font-size: 14px;
  }

  &:hover {
    outline: none;
  }

  &:focus {
    outline: none;
    background: ${colors.dashboard_light_grey};
  }
`;

export const SubmitButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

export const DeleteModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(34, 34, 34, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7;
`;

export const DeleteModal = styled.div`
  background: #fff;
  width: 350px;
  height: 250px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  z-index: 10;
`;

export const CancelConfirmButton = styled.div<{ isConfirm?: boolean }>`
  background: ${({ isConfirm }) =>
    isConfirm ? `${colors.purple_dark}` : `${colors.white}`};
  color: ${({ isConfirm }) => isConfirm && `${colors.white}`};
  display: flex;
  height: 41px;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  align-self: stretch;
  border-radius: 8px;
  cursor: pointer;
`;

export const StyledSelectMenu = styled.select<{ width?: string }>`
  -webkit-appearance: none;
  display: flex;
  padding: 14px 16px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 0.2px solid ${colors.grey_light};
  background: ${colors.white};
  font-family: ${font.family};
  color: ${colors.black};
  background: ${colors.dashboard_light_grey};
  width: ${({ width }) => (width ? `${width}` : '100%')};
  outline: none;

  a .items &:hover {
    border: 1px solid #5c5d5d;
    border: 1px solid ${colors.purple_dark};
  }

  &:focus {
    outline: none;
    background: ${colors.white};
  }
`;

export const SkillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
`;

export const SkillButton = styled.button`
  margin: 4px;
  padding: 8px 10px;
  background-color: ${colors.white};
  border: none;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const DeleteButton = styled.span`
  margin-left: 10px;
  padding: 0 6px;
  cursor: pointer;
`;

export const ErrorLabel = styled.label`
  font-family: ${font.family};
  font-size: 12px;
  font-weight: 500;
  color: ${colors.error};
  display: block;
  margin-top: 6px;
  margin-left: 10px;
`;

export const TopNavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const JobVacancyAIContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const OffersButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const JobOffersContainer = styled.div<{
  isRelative?: boolean;
  width?: string;
}>`
  position: ${({ isRelative }) => (isRelative ? 'relative;' : 'static;')};
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: ${({ width }) => (width ? width : '100%;')};
`;

export const WrapperDiv = styled.div<{
  gap?: string;
  mt?: string;
  mb?: string;
  justifyContent?: string;
  flexWrap?: boolean;
  height?: string;
  alignItems?: string;
  flexDirection?: string;
}>`
  display: flex;
  gap: ${({ gap }) => (gap ? gap : '10px;')};
  ${({ mt }) => mt && `margin-top: ${mt};`};
  ${({ mb }) => mb && `margin-bottom: ${mb};`};
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent};`};
  ${({ flexWrap }) => (flexWrap ? `flex-wrap: wrap;` : `flex-wrap: no-wrap;`)};
  ${({ height }) => height && `height: ${height};`};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`};
  ${({ flexDirection }) =>
    flexDirection && `flex-direction: ${flexDirection};`};
`;

export const StyledList = styled.li`
  margin-bottom: 5px;
`;

export const ToggleSwitchWrapper = styled.label`
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-left: 20px;
  z-index: 2;
`;

export const ToggleSwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + .slider {
    background-color: ${colors.primary_500};
  }

  &:focus + .slider {
    box-shadow: 0 0 1px ${colors.grey};
  }

  &:checked + .slider:before {
    transform: translateX(20px);
  }
`;

export const ToggleSwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 10px;

  &:before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;
