import React, { useEffect, useState } from 'react';
import { JobOfferWithCompanyData } from '../../../interfaces/job-interfaces';
import { fetchAllItems } from '../../requests/requests';
import { useParams, useNavigate } from 'react-router-dom';
import { Spinner } from '../../icons/spinner';
import {
  ContentAndImageContainer,
  ImageContainer,
  CoverImage,
  Overlay,
  CenteredText,
  JobPostingAndInfoContainer,
  JobPostingAndInfoWrapper,
  JobPostInfoContainerMobile,
  CareerText,
} from './company-job-board-table.styled';
import { JobPostInfo } from './job-post-info';
import { Company } from '../../app-router/component-props';
import { TopMenu } from '../../layout/top-menu/top-menu';
import {
  JobBoardContainer,
  JobBoardWrapper,
} from '../job-board/job-board.styled';
import { colors } from '../../../style-utils/colors';
import axios from 'axios';
import { apiRoute } from '../../../constants/api-constants';
import { ColorTemplate } from './company-job-board';

interface JobPostMobileProps {
  company?: Company | null;
}

export const JobPostMobile: React.FC<JobPostMobileProps> = ({
  company: propCompany,
}) => {
  const [selectedJob, setSelectedJob] =
    useState<JobOfferWithCompanyData | null>(null);
  const [company, setCompany] = useState<Company | null>(propCompany || null);
  const [colorTemplateLoaded, setColorTemplateLoaded] = useState(false);
  const [colorTemplate, setColorTemplate] = useState<ColorTemplate>({
    title_color: '#010101',
    company_title_color: '#E94E3C',
    text_color: '#010101',
    filter_text_color: '#5E5A57',
    filter_color: '#F3F2F2',
    button_text_color: '#FDFDFD',
    button_color: '#E94E3C',
    background_fill_color: '#FDFDFD',
    background_color: '#F7F8FA',
  });
  const { companyId, jobId } = useParams();
  const navigate = useNavigate();

  const JOB_API_URL = `company/jobs/${companyId}/${jobId}`;
  const COMPANY_API_URL = `company/${companyId}`;
  const APPLY_PATH = (jobId: string) => `/apply/${companyId}/${jobId}`;

  const fetchJobData = async () => {
    if (companyId && jobId) {
      const response = await fetchAllItems(JOB_API_URL);
      const job = response as unknown as JobOfferWithCompanyData;
      setSelectedJob(job);
    }
  };

  const fetchCompanyData = async () => {
    if (!propCompany && companyId) {
      const response = await fetchAllItems(COMPANY_API_URL);
      const { company } = response as unknown as { company: Company };
      setCompany(company);
    }
  };

  useEffect(() => {
    fetchJobData();
    fetchCompanyData();
  }, [companyId, jobId, propCompany]);

  useEffect(() => {
    if (company?.id) {
      fetchColorTemplate(company.id);
    }
  }, [company]);

  const fetchColorTemplate = async (companyId: string) => {
    setColorTemplateLoaded(false);
    try {
      const response = await axios.get<ColorTemplate>(
        `${apiRoute}/color_template/${companyId}`
      );
      if (response.status === 200 && response.data) {
        setColorTemplate(response.data);
      } else {
        console.log('No color template found, using default values.');
      }
    } catch (error) {
      console.error('Error fetching color template:', error);
    } finally {
      setColorTemplateLoaded(true);
    }
  };

  if (!company || !selectedJob || !colorTemplateLoaded) {
    return <Spinner />;
  }

  return (
    <JobBoardWrapper>
      <JobBoardContainer bgColor={colors.base_white} color={colors.base_black}>
        <TopMenu
          companyLogoUrl={company?.profile_picture}
          bigLogo
          navigationUrl={company && `/${company.id}/job-board`}
          hideUserAndNotification
        />
      </JobBoardContainer>
      <ContentAndImageContainer>
        <ImageContainer>
          <CoverImage src="/coverImage.jpeg" alt="Cover" />
          <Overlay />
          <CenteredText>
            Your Best way to Exciting&nbsp;<CareerText>Career</CareerText>
            &nbsp;Opportunities
          </CenteredText>
        </ImageContainer>
        <JobPostingAndInfoContainer>
          <JobPostingAndInfoWrapper>
            <JobPostInfoContainerMobile>
              <JobPostInfo
                job={selectedJob}
                company={company}
                toggleApplyForm={() => navigate(APPLY_PATH(selectedJob.id))}
                colorTemplate={colorTemplate}
              />
            </JobPostInfoContainerMobile>
          </JobPostingAndInfoWrapper>
        </JobPostingAndInfoContainer>
      </ContentAndImageContainer>
    </JobBoardWrapper>
  );
};
