import React, { useEffect, useState } from 'react';
import {
  Company,
  DefaultComponentProps,
} from '../../app-router/component-props';
import { useWindowHeight } from '../../../style-utils/use-window-height';
import {
  ContentWrapper,
  LayoutContainer,
  MainContentWrapper,
  NavbarAndContentTransparentContainer,
} from '../../layout/layout-container.styled';
import { TopMenu } from '../../layout/top-menu/top-menu';
import { Sidebar } from '../../layout/sidebar/sidebar';
import BackToDashboard from './components/back-btn/back-btn';
import CompanyInfo from './components/company-info/company-info';
import SettingsTabs from './components/tabs/tabs-main';
import EditCompanyInfo from './components/company-info/edit-company-info';
import axios from 'axios';
import { apiRoute } from '../../../constants/api-constants';
import { Chat } from '../../chat/chat';

const SettingsMain: React.FC<DefaultComponentProps> = ({
  user,
  navigate,
  cookies,
}) => {
  const height = useWindowHeight();
  const [editCompanyInfoVisible, setEditCompanyInfoVisible] =
    useState<boolean>(false);
  const [companyData, setCompanyData] = useState<Company>(user);

  useEffect(() => {
    if (user && user.id) {
      fetchCompanyData();
    }
  }, [user]);

  const fetchCompanyData = () => {
    return axios
      .get(`${apiRoute}/company/${user.id}`)
      .then((response) => {
        console.log(
          'Company data retrieved successfully:',
          response.data.company,
          user
        );
        setCompanyData(response.data.company);
      })
      .catch((error) => {
        console.error('Error retrieving company data:', error);
        throw error;
      });
  };

  return (
    <LayoutContainer height={height}>
      <TopMenu />
      <MainContentWrapper>
        <Sidebar company={user} selectedOption={''} navigate={navigate} />
        <ContentWrapper>
          <NavbarAndContentTransparentContainer>
            <BackToDashboard user={user} navigate={navigate} />
            {!editCompanyInfoVisible && (
              <CompanyInfo
                user={companyData}
                setEditCompanyInfoVisible={setEditCompanyInfoVisible}
              />
            )}
            {editCompanyInfoVisible && (
              <EditCompanyInfo
                user={companyData}
                setEditCompanyInfoVisible={setEditCompanyInfoVisible}
                fetchCompanyData={fetchCompanyData}
              />
            )}
            <SettingsTabs user={user} cookies={cookies} />
          </NavbarAndContentTransparentContainer>
        </ContentWrapper>
      </MainContentWrapper>
      <Chat />
    </LayoutContainer>
  );
};

export default SettingsMain;
