import styled from 'styled-components';
import { media } from '../../../style-utils/media-queries';

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #171718;
`;

export const ContentContainer = styled.div`
  width: 90%;
  max-width: 1440px;
  padding: 50px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${media.laptop(`
    flex-direction: column;
    gap: 60px;
  `)}
`;

export const Logo = styled.img`
  width: 150px;
  padding-bottom: 15px;
`;

export const FooterLeft = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  ${media.laptop(`
    width: 90%;
  `)}
`;

export const FooterLists = styled.ul`
  list-style-type: none;
  padding-inline-start: 0px;
  margin: 0;
`;

export const ListTitle = styled.li`
  font-weight: 600;
  font-size: 17px;
  padding-bottom: 15px;
  color: white;
`;

export const ListItemLeft = styled.li`
  padding-top: 10px;
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const EmailLink = styled.a`
  color: white;
  text-decoration: underline;
`;

export const ListItem = styled.li`
  padding-top: 10px;
  display: flex;
  gap: 10px;
  cursor: pointer;
  color: white;

  &:hover {
    font-weight: 500;
    text-decoration: underline;
  }
`;

export const Links = styled.a`
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  color: white;
`;

export const FooterRight = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  list-style-type: none;
  gap: 90px;

  ${media.laptop(`
    width: 90%;
    gap: 30px;
    justify-content: flex-start;width: 90%;
    gap: 30px;
    justify-content: flex-start;
  `)}
`;

export const FollowLinks = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SocialMediaLinks = styled.div`
  display: flex;
  gap: 10px;
`;

export const UnderFooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ContentUnder = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${media.laptop(`
    width: 80%;
    padding-bottom: 10px;
    flex-direction: column;
    align-items: flex-start;
  `)}
`;

export const Copyright = styled.p`
  width: 50%;
  font-size: 14px;
  font-weight: 500;

  ${media.laptop(`
    width: 100%;
    font-size: 11px;
    font-weight: 400;
    color: #282828;
  `)}
`;

export const TermsPolicy = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;

  ${media.laptop(`
    justify-content: flex-start;
    width: 100%;
  `)}
`;

export const LinksDown = styled.div`
  font-size: 14px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  ${media.laptop(`
    font-size: 13px;
  `)}
`;
