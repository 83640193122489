import React from 'react';
import {
  ContentWrapper,
  LayoutContainer,
  NavbarAndContentContainer,
  MainContentWrapper,
} from '../../layout/layout-container.styled';
// import { Text, font } from '../../../style-utils/text';
import { useWindowHeight } from '../../../style-utils/use-window-height';
import { TopMenu } from '../../layout/top-menu/top-menu';
import { NavigateFunction } from 'react-router-dom';
import { Cookie } from 'universal-cookie';
import { SuperAdminSidebar } from '../../layout/sidebar/super-admin-sidebar';

interface SuperAdminSettingsProps {
  navigate: NavigateFunction;
  // eslint-disable-next-line
  user: any;
  cookies?: Cookie;
  viewAnalysis?: boolean;
  toggleAnalysis?: () => void;
}

export const SuperAdminSettings: React.FC<SuperAdminSettingsProps> = ({
  navigate,
  user,
}) => {
  const height = useWindowHeight();
  const { account_type } = user;
  console.log('type:::::');
  console.log(account_type);

  return (
    <LayoutContainer height={height}>
      <TopMenu />
      <MainContentWrapper>
        <SuperAdminSidebar company={user} navigate={navigate} />
        <ContentWrapper>
          <NavbarAndContentContainer>SETTINGSSSSS</NavbarAndContentContainer>
        </ContentWrapper>
      </MainContentWrapper>
    </LayoutContainer>
  );
};
