import React from 'react';
import {
  FooterContainer,
  FooterElements,
  Links,
  Copyright,
  SocialMediaIcons,
} from './footer.styled';
import { LinkedinIcon } from '../icons/linkedin';
import { FacebookIcon } from '../icons/facebook';
import { InstagramIcon } from '../icons/instagram';
import { Link } from 'react-router-dom';

export const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <FooterElements>
        <Links>
          <p>Terms of Use</p>|<p>Privacy Policy</p>
        </Links>
        <Copyright>Copyright 2024 © All rights by Worwise</Copyright>
        <SocialMediaIcons>
          <Link to="https://www.linkedin.com/company/worwise" target="blank">
            <LinkedinIcon />
          </Link>
          <Link
            to="https://www.facebook.com/people/WorWise/61564151662235/"
            target="blank"
          >
            <FacebookIcon />
          </Link>
          <Link to="https://www.instagram.com/worwise_/" target="blank">
            <InstagramIcon />
          </Link>
        </SocialMediaIcons>
      </FooterElements>
    </FooterContainer>
  );
};
