import React, { createContext, useContext, useState, ReactNode } from 'react';
import axios from 'axios';
import { apiRoute } from '../constants/api-constants';

export interface Notification {
  type: string;
  id: number;
  entity_id: string;
  entity_type: string;
  title: string;
  message: string;
  read: boolean;
}

interface NotificationsContextProps {
  notifications: Notification[];
  fetchNotifications: (userId: string) => void;
  createNotification: (notification: Omit<Notification, 'id' | 'read'>) => void;
  updateNotification: (id: number, updatedData: Partial<Notification>) => void;
  deleteNotification: (id: number) => void;
  markAsRead: (id: number) => void;
}

export const NotificationsContext = createContext<
  NotificationsContextProps | undefined
>(undefined);

export const useNotifications = (): NotificationsContextProps => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error(
      'useNotifications must be used within a NotificationsProvider'
    );
  }

  return context;
};

interface NotificationsProviderProps {
  children: ReactNode;
}

export const NotificationsProvider: React.FC<NotificationsProviderProps> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const fetchNotifications = async (userId: string) => {
    try {
      const response = await axios.get<Notification[]>(
        `${apiRoute}/notifications/user/${userId}`
      );
      setNotifications(response.data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const createNotification = async (
    notification: Omit<Notification, 'id' | 'read'>
  ) => {
    try {
      const response = await axios.post<Notification>(
        `${apiRoute}/notifications`,
        notification
      );
      setNotifications([...notifications, response.data]);
    } catch (error) {
      console.error('Error creating notification:', error);
    }
  };

  const updateNotification = async (
    id: number,
    updatedData: Partial<Notification>
  ) => {
    try {
      const response = await axios.put<Notification>(
        `${apiRoute}/notifications/${id}`,
        updatedData
      );
      setNotifications(
        notifications.map((notification) =>
          notification.id === id ? response.data : notification
        )
      );
    } catch (error) {
      console.error('Error updating notification:', error);
    }
  };

  const deleteNotification = async (id: number) => {
    try {
      await axios.delete(`${apiRoute}/notifications/${id}`);
      setNotifications(
        notifications.filter((notification) => notification.id !== id)
      );
    } catch (error) {
      console.error('Error deleting notification:', error);
    }
  };

  const markAsRead = async (id: number) => {
    try {
      await axios.put(`${apiRoute}/notifications/${id}/read`);
      setNotifications(
        notifications.map((notification) =>
          notification.id === id
            ? { ...notification, read: true }
            : notification
        )
      );
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        fetchNotifications,
        createNotification,
        updateNotification,
        deleteNotification,
        markAsRead,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
