import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import styled from 'styled-components';

interface StripeSubscriptionProps {
  email: string;
  planId: string;
  amount: number;
}

const StripeSubscription: React.FC<StripeSubscriptionProps> = ({
  // email,
  // planId,
  amount,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | undefined>(undefined);
  // eslint-disable-next-line
  const [paymentIntent, setPaymentIntent] = useState<any>(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      setError('Stripe.js has not yet loaded.');

      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setError('Card Element not found.');

      return;
    }

    try {
      const {
        data: { clientSecret },
      } = await axios.post('/api/create-payment-intent', {
        amount, // Amount should be in cents
        currency: 'usd',
      });

      const paymentMethodReq = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (paymentMethodReq.error) {
        setError(paymentMethodReq.error.message);

        return;
      }

      const confirmCardPayment = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodReq.paymentMethod.id,
      });

      if (confirmCardPayment.error) {
        setError(confirmCardPayment.error.message);
      } else {
        setPaymentIntent(confirmCardPayment.paymentIntent);
      }
      // eslint-disable-next-line
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <StyledButton type="submit" disabled={!stripe}>
        Checkout with Stripe
      </StyledButton>
      {error && <div>{error}</div>}
      {paymentIntent && <div>Subscription created!</div>}
    </form>
  );
};

const StyledButton = styled.button`
  background-color: #0070ba;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 16px;
  margin-bottom: 16px;

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

export default StripeSubscription;
