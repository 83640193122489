import styled from 'styled-components';
import { colors } from '../../../style-utils/colors';
import { font } from '../../../style-utils/text';

export const AddEmployeeHeaderContainer = styled.div`
  box-sizing: border-box;
  margin-left: 25px;
  margin-bottom: 32px;
`;

export const AddEmployeeFormContainer = styled.div`
  box-sizing: border-box;
  padding: 40px;
  background-color: ${colors.base_white};
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 800px;
  border-radius: 16px;
  align-self: center;
`;

export const AddEmployeeSectionContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const AddEmployeeInputAndErrorContainer = styled.div<{
  width?: string;
  isRelative?: boolean;
}>`
  box-sizing: border-box;
  width: ${({ width }) => width};
  ${({ isRelative }) => isRelative && `position: relative;`};

  & > * {
    box-sizing: border-box;
  }
`;

export const AddEmployeeStyledButton = styled.button<{
  padding?: string;
  color?: string;
  bgColor?: string;
  borderRadius?: string;
  width?: string;
  alignSelf?: string;
}>`
  box-sizing: border-box;
  border: 0;
  border-style: none;
  padding: ${({ padding }) => padding && `${padding};`};
  color: ${({ color }) => color && `${color};`};
  background-color: ${({ bgColor }) => bgColor && `${bgColor};`};
  border-radius: ${({ borderRadius }) => borderRadius && `${borderRadius};`};
  width: ${({ width }) => width && `${width};`};
  font-family: ${font.family};
  align-self: ${({ alignSelf }) => alignSelf && `${alignSelf};`};
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 700;
  cursor: pointer;
`;

export const EducationListWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  margin-top: 14px;
  margin-bottom: -40px;
`;

export const CustomUploadLabel = styled.label`
  box-sizing: border-box;
  display: block;
  padding: 12px 16px;
  font-family: ${font.family};
  font-weight: 700;
  font-size: 16px;
  line-height: 19.2px;
  text-align: center;
  border-radius: 4px;
  background-color: ${colors.secondary_50};
  color: ${colors.base_black};
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const HiddenUploadInput = styled.input`
  box-sizing: border-box;
  opacity: 0;
  visibility: hidden;
  display: none;
`;

export const StyledSelect = styled.select`
  box-sizing: border-box;
  -webkit-appearance: none;
  border: 0.2px solid ${colors.grey_light};
  background-color: ${colors.neutral_50};
  padding: 14px 20px;
  border-radius: 8px;
  position: relative;
  width: 100%;
`;

export const DownArrowContainer = styled.div<{
  right?: string;
  top?: string;
  isOpen?: boolean;
  height?: string;
  width?: string;
}>`
  box-sizing: border-box;
  position: absolute;
  right: ${({ right }) => right && `${right};`};
  top: ${({ top }) => top && `${top};`};
  ${({ isOpen }) => isOpen && `rotate: 180deg;`};
  ${({ height }) => height && `height: ${height};`};
  ${({ width }) => width && `width: ${width};`};
`;

export const AddEmployeeProfilePictureArea = styled.div`
  box-sizing: border-box;
  display: flex;
  gap: 64px;
`;

export const AddEmployeeProfilePictureContainer = styled.div`
  box-sizing: border-box;
  width: 162px;
  height: 162px;
  border-radius: 50%;
  background-color: ${colors.neutral_50};
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    display: block;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const AddEmployeeProfilePictureHeader = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
`;

export const AddEmployeeStyledSelectWrapper = styled.div<{
  width?: string;
  alignSelf?: string;
  padding?: string;
}>`
  box-sizing: border-box;
  padding: ${({ padding }) => padding && `${padding};`};
  background-color: ${colors.neutral_50};
  border-radius: 8px;
  width: ${({ width }) => width && `${width};`};
  position: relative;
  display: flex;
  align-items: center;
  ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf};`};
`;

export const AddEmployeeStyledOptionsWrapper = styled.div<{ height?: string }>`
  box-sizing: border-box;
  padding: 16px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50px;
  border: 1px solid ${colors.neutral_900};
  border-radius: 8px;
  height: ${({ height }) => (height ? `${height};` : '200px;')};
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  background-color: ${colors.base_white};
  gap: 8px;
  z-index: 10;
  scrollbar-width: none;
  /* WebKit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 1px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: darkgrey transparent;
`;

export const AddEmployeeStyledOption = styled.div`
  box-sizing: border-box;
  font-size: 14px;
  font-family: ${font.family};
  color: ${colors.neutral_900};
  line-height: 16.8px;
  cursor: pointer;
`;

export const BasicWrapper = styled.div<{ width?: string }>`
  box-sizing: border-box;
  ${({ width }) => width && `width: ${width};`};
`;

export const HiddenInputsWrapper = styled.div`
  box-sizing: border-box;
`;

export const ViewAllInputsBtn = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-style: none;
  font-family: ${font.family};
  font-size: 12px;
  font-weight: 700;
  color: ${colors.neutral_700};
  cursor: pointer;
`;
