import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { apiRoute } from '../../../constants/api-constants';
import { RegisterUser } from '../../register/register-user';
import { Spinner } from '../../icons/spinner';
import { Company } from '../../app-router/component-props';
import { User } from '../../../interfaces/user-interfaces';
import { EmployeeData } from '../../../interfaces/employee-interfaces';

interface ErrorResponse {
  message: string;
  // Include other fields that might be in your error response
}

export const EmailVerification = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [accountType, setAccountType] = useState('');
  const [accountData, setAccountData] = useState({});

  useEffect(() => {
    setIsLoading(true);
    const verifyEmail = async () => {
      const token = searchParams.get('token');
      if (!token) {
        console.log('Token not found');
        navigate('/');

        return;
      }

      try {
        const res = await axios.post(`${apiRoute + location.pathname}`, {
          token,
        });
        console.log('API Response:', res.data);
        if (res.data.data && res.data.data.type) {
          setAccountType(res.data.data.type);
          setAccountData(res.data.data);
        } else {
          console.error('Invalid or missing type in API response:', res.data);
          setError('Invalid response from server');
        }
      } catch (error) {
        console.error('Error verifying email:', error as AxiosError);
        const displayError = error as AxiosError<ErrorResponse>;
        setError(
          (displayError.response?.data?.message as string) ||
            'Error verifying email'
        );
      } finally {
        setIsLoading(false);
      }
    };

    verifyEmail();
  }, [searchParams, navigate]);

  console.log('Account Type:', accountType); // Debugging log

  useEffect(() => {
    console.log('accountType has changed:', accountType);
  }, [accountType]);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!accountType) {
    return <div>Account type not set. Please try again.</div>;
  }

  return (
    <RegisterUser
      type={accountType}
      navigate={navigate}
      user={
        accountType === 'company'
          ? (accountData as Company)
          : accountType === 'employee'
          ? (accountData as EmployeeData)
          : (accountData as User)
      }
    />
  );
};
