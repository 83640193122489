import React, { useEffect, useState } from 'react';
import {
  ApplicantsContentWrapper,
  HeadingWrapper,
  StyledTable,
  StyledTd,
  StyledTh,
  StyledThead,
  StyledTr,
  WrapperDiv,
  StyledSelect,
  StyledSearchInput,
} from '../../employees/employees.styled';
import { ButtonContainer } from '../../../login/login.styled';
import { fetchAllItems } from '../../../requests/requests';
import { Text, font } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';
import { usePagination } from '../../../hooks/use-pagination';
import { PaginationButtons } from '../../../table-pagination/table-pagination';
import { Company } from '../../../app-router/component-props';
import { formatDateToLocal } from '../../../../function-utils/utc-date-converter';
// import AdminSettingsTabs from '../company-info/settings/admin-settings';
import AdminCompanyInfo from '../company-info/admin-company-info';

export const SuperAdminCompanies = () => {
  const [companiesData, setCompaniesData] = useState<Company[]>([]);
  const [filteredData, setFilteredData] = useState<Company[]>([]);
  const [filterBy, setFilterBy] = useState('');
  const [searchWord, setSearchWord] = useState('');
  const [editingCompany, setEditingCompany] = useState<Company | null>(null);

  const getCompanies = async () => {
    const response = await fetchAllItems(`/all-companies`);
    setCompaniesData(response as Company[]);
    setFilteredData(response as Company[]);
  };

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    const filterAndSearch = () => {
      let filtered = companiesData;

      if (searchWord) {
        const lowerCaseSearchWord = searchWord.toLowerCase();

        filtered = companiesData.filter((company) => {
          return (
            company.createdAt?.toLowerCase().includes(lowerCaseSearchWord) ||
            company.name?.toLowerCase().includes(lowerCaseSearchWord) ||
            company.status?.toLowerCase().includes(lowerCaseSearchWord)
          );
        });
      }

      if (filterBy && searchWord) {
        filtered = filtered.filter((company) => {
          const lowerCaseSearchWord = searchWord.toLowerCase();
          switch (filterBy) {
            case 'Create Date':
              return company.createdAt
                ?.toLowerCase()
                .includes(lowerCaseSearchWord);
            case 'Company':
              return company.name?.toLowerCase().includes(lowerCaseSearchWord);
            case 'Status':
              return company.status
                ?.toLowerCase()
                .includes(lowerCaseSearchWord);
            default:
              return true;
          }
        });
      }

      setFilteredData(filtered);
    };

    filterAndSearch();
  }, [filterBy, searchWord, companiesData]);

  const {
    currentPage,
    displayedData,
    nextPage,
    previousPage,
    hasPreviousPage,
    hasNextPage,
    getTotalPages,
  } = usePagination(filteredData, 10);

  const handleEditCompany = (company: Company) => {
    setEditingCompany(company);
  };

  const handleBackToList = () => {
    setEditingCompany(null);
  };

  if (editingCompany) {
    return (
      <AdminCompanyInfo handleBack={handleBackToList} user={editingCompany} />
    );
  }

  return (
    <ApplicantsContentWrapper>
      <div style={{ overflowX: 'auto' }}>
        <HeadingWrapper>
          <Text mt={0} mb={0} fontSize={32} fontFamily={font.family}>
            Companies
          </Text>
          <WrapperDiv>
            <StyledSearchInput
              type="text"
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
              placeholder="Search..."
            />
            <StyledSelect
              value={filterBy}
              onChange={(e) => setFilterBy(e.target.value)}
            >
              <option value="">Filter by</option>
              <option value="Create Date">Create Date</option>
              <option value="Company">Company</option>
              <option value="Status">Status</option>
            </StyledSelect>
            <ButtonContainer
              backgroundColor={colors.primary_400}
              // onClick={handleAddNewAdmin}
            >
              <Text
                mt={0}
                mb={0}
                align="center"
                pl={20}
                pr={20}
                fontFamily={font.family}
                fontWeight={700}
              >
                Add new Company
              </Text>
            </ButtonContainer>
          </WrapperDiv>
        </HeadingWrapper>
        <StyledTable>
          <StyledThead>
            <StyledTr>
              <StyledTh columnWidth={'120px'}>Create Date</StyledTh>
              <StyledTh columnWidth={'694px'}>Company</StyledTh>
              <StyledTh columnWidth={'120px'}>Status</StyledTh>
              <StyledTh columnWidth={'120px'}>Action</StyledTh>
            </StyledTr>
          </StyledThead>
          <tbody>
            {displayedData?.map((company, index) => (
              <StyledTr key={index}>
                <StyledTd data-label="created-at">
                  {formatDateToLocal(company.createdAt)}
                </StyledTd>
                <StyledTd data-label="name">{company?.name}</StyledTd>
                <StyledTd data-label="status">
                  <Text color={company?.status === 'Active' ? 'green' : 'red'}>
                    {company?.status}
                  </Text>
                </StyledTd>
                <StyledTd>
                  <ButtonContainer
                    backgroundColor={colors.secondary_400}
                    onClick={() => handleEditCompany(company)}
                  >
                    <Text
                      mt={0}
                      mb={0}
                      align="center"
                      pl={20}
                      pr={20}
                      fontFamily={font.family}
                      fontWeight={700}
                    >
                      Edit
                    </Text>
                  </ButtonContainer>
                </StyledTd>
              </StyledTr>
            ))}
          </tbody>
        </StyledTable>
      </div>
      <div style={{ width: '60%' }}>
        <PaginationButtons
          currentPage={currentPage}
          hasPreviousPage={hasPreviousPage}
          hasNextPage={hasNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
          totalPages={getTotalPages()}
        />
      </div>
    </ApplicantsContentWrapper>
  );
};
