import React from 'react';
import { DefaultComponentProps } from '../../../app-router/component-props';
import {
  ContentWrapper,
  NavbarAndContentTransparentContainer,
} from '../../../layout/layout-container.styled';
import BackToDashboard from '../../settings/components/back-btn/back-btn';
import AdminInfo from './admin-info';
import AdminSettingsTabs from './settings/admin-settings';

const AdminCompanyInfo: React.FC<DefaultComponentProps> = ({
  user,
  navigate,
  cookies,
  handleBack,
}) => {
  return (
    <ContentWrapper>
      <NavbarAndContentTransparentContainer>
        <BackToDashboard
          handleBack={handleBack}
          user={user}
          navigate={navigate}
        />
        <AdminInfo user={user} />
        <AdminSettingsTabs user={user} cookies={cookies} />
      </NavbarAndContentTransparentContainer>
    </ContentWrapper>
  );
};

export default AdminCompanyInfo;
