import React, { useEffect, useRef, useState } from 'react';
import {
  LogInWrapper,
  LogInFormContainer,
  LogInForm,
  LogInTitle,
  TextContainer,
  CompanyTitle,
  Description,
  InputContainer,
  Label,
  StyledInput,
  ButtonTitle,
  BlueButton,
  LogInFormWrapper,
  LoginSignupWrapper,
  LoginOrSignupButton,
  ShowHideText,
  ErrorMessage,
  ButtonsContainer,
} from '../login/login.styled';
import logoTeam from '../../logo_team.png';
import { NavigateFunction } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { apiRoute } from '../../constants/api-constants';
import toast from 'react-hot-toast';
import { Spinner } from '../icons/spinner';
import { colors } from '../../style-utils/colors';
import { font, Text } from '../../style-utils/text';

interface SetNewPasswordProps {
  navigate: NavigateFunction;
}

export const SetNewPassword: React.FC<SetNewPasswordProps> = ({ navigate }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const passwordInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (passwordInputRef.current) {
      passwordInputRef.current.focus();
    }
  }, []);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');

      return;
    }

    if (!password || !confirmPassword) {
      setErrorMessage('Please fill out both password fields');

      return;
    }

    setIsLoading(true);
    setErrorMessage(null);

    try {
      const token = new URLSearchParams(window.location.search).get('token');
      if (!token) {
        setErrorMessage('Invalid or missing token');
        setIsLoading(false);

        return;
      }

      await axios.post(
        `${apiRoute}/reset-password`,
        {
          token,
          newPassword: password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      toast.success('Your password has been successfully reset');
      navigate('/');
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        switch (axiosError.response.status) {
          case 400:
            setErrorMessage('Invalid or expired token');
            break;
          case 500:
            setErrorMessage('Internal server error. Please try again later.');
            break;
          default:
            setErrorMessage(
              'An unexpected error occurred. Please try again later.'
            );
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogoClick = () => {
    if (navigate) navigate('/');
  };

  return (
    <LogInWrapper id="log-in-form">
      <TextContainer>
        <img
          onClick={handleLogoClick}
          style={{ marginTop: '60px', cursor: 'pointer' }}
          src={logoTeam}
          width={100}
        />
        <CompanyTitle>Welcome to Worwise</CompanyTitle>
        <Description>
          Please create a new password to access your account.
        </Description>
      </TextContainer>
      <LogInFormWrapper id="LoginFormWrapper">
        <LogInFormContainer overflow="none">
          <LogInForm onSubmit={onSubmit}>
            {isLoading && <Spinner />}
            <LoginSignupWrapper>
              <LoginOrSignupButton isSelected>
                <LogInTitle isSelected>Create New Password</LogInTitle>
              </LoginOrSignupButton>
            </LoginSignupWrapper>
            <InputContainer>
              <Label>New Password</Label>
              <StyledInput
                ref={passwordInputRef}
                bgColor={colors.neutral_50}
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={password}
                placeholder={'Enter new password'}
                onChange={(e) => setPassword(e.target.value)}
              />
              <ShowHideText>
                <Text
                  onClick={() => setShowPassword(!showPassword)}
                  color={colors.primary_700}
                  fontFamily={font.family}
                  fontSize={14}
                  fontWeight={700}
                >
                  {showPassword ? 'Hide' : 'Show'}
                </Text>
              </ShowHideText>
            </InputContainer>
            <InputContainer>
              <Label>Confirm Password</Label>
              <StyledInput
                bgColor={colors.neutral_50}
                type={showPassword ? 'text' : 'password'}
                name="confirmPassword"
                value={confirmPassword}
                placeholder={'Confirm new password'}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <ShowHideText>
                <Text
                  onClick={() => setShowPassword(!showPassword)}
                  color={colors.primary_700}
                  fontFamily={font.family}
                  fontSize={14}
                  fontWeight={700}
                >
                  {showPassword ? 'Hide' : 'Show'}
                </Text>
              </ShowHideText>
            </InputContainer>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <ButtonsContainer>
              <BlueButton type="submit">
                <ButtonTitle fontColor={'white'}>
                  Confirm New Password
                </ButtonTitle>
              </BlueButton>
            </ButtonsContainer>
          </LogInForm>
        </LogInFormContainer>
      </LogInFormWrapper>
    </LogInWrapper>
  );
};
