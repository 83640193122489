import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Text, font } from '../../../../../style-utils/text';
import {
  ModalBackground,
  ModalContent,
  InputsContainer,
  Row,
  InputAndTextContainer,
  ButtonsContainer,
  Button,
  StyledSelect,
  StyledInput,
  ErrorText,
} from './modal.styled';
import axios from 'axios';
import { fetchAllItems } from '../../../../requests/requests';
import { apiRoute } from '../../../../../constants/api-constants';
import { colors } from '../../../../../style-utils/colors';

const roles = ['HR', 'Admin', 'Manager', 'Developer'];

interface AddAdminModalProps {
  onClose: () => void;
  fromPage?: string;
  setPopUp: Dispatch<
    SetStateAction<{ text: string; type: 'success' | 'error' } | null>
  >;
  companyId?: string;
}

interface Company {
  id: string;
  name: string;
}

export const AddAdminModal: React.FC<AddAdminModalProps> = ({
  onClose,
  fromPage,
  setPopUp,
  companyId,
}) => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    company_id: '',
    password: '',
    confirmPassword: '',
    role: 'HR',
    phone: '',
  });
  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company_id: '',
    password: '',
    confirmPassword: '',
    role: '',
    phone: '',
  });

  useEffect(() => {
    if (fromPage === 'adminSidebar') {
      setFormData({
        ...formData,
        company_id: companyId ?? '',
      });
    } else {
      const fetchCompanies = async () => {
        try {
          const data = await fetchAllItems('companies');
          setCompanies(data as Company[]);
        } catch (error) {
          console.error('Error fetching companies:', error);
        }
      };

      fetchCompanies();
    }
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCompanyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCompanyId = e.target.value;
    setFormData({
      ...formData,
      company: selectedCompanyId,
      company_id: selectedCompanyId,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+\d+$/;

    // eslint-disable-next-line
    const errors: any = {};

    if (!formData.firstName) {
      errors.firstName = 'First name is required.';
    }

    if (!formData.lastName) {
      errors.lastName = 'Last name is required.';
    }

    if (!emailRegex.test(formData.email)) {
      errors.email = 'Please enter a valid email (e.g. example@mail.com)';
    }

    if (fromPage === 'adminSidebar' && !phoneRegex.test(formData.phone)) {
      errors.phone =
        'Phone number must start with + followed by digits (e.g. +38975000000)';
    } else {
      if (!formData.company_id) {
        errors.company_id = 'Company selection is required.';
      }
    }

    if (!formData.password) {
      errors.password = 'Password is required.';
    }

    if (!formData.confirmPassword) {
      errors.confirmPassword = 'Confirm password is required.';
    }

    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match.';
    }

    if (!formData.role) {
      errors.role = 'Role is required.';
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);

      return;
    }

    try {
      const response = await axios.post(`${apiRoute}/create-admin`, {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        password: formData.password,
        role: formData.role,
        company_id: formData.company_id,
      });

      if (response.status === 200) {
        setPopUp({
          text: `New ${formData.role} have been successfully added!`,
          type: 'success',
        });
        onClose();
      } else {
        setPopUp({
          text: `Error: ${response.data.message}`,
          type: 'error',
        });
      }
    } catch (error) {
      console.error('Error creating admin:', error);
      alert('Error creating admin');
      setPopUp({
        text: 'Error creating admin',
        type: 'error',
      });
    }
  };

  return (
    <ModalBackground onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <form onSubmit={handleSubmit}>
          <Text
            mt={0}
            mb={16}
            fontSize={28}
            fontFamily={font.family}
            fontWeight={700}
            color={colors.base_black}
          >
            Add A New Admin
          </Text>

          <InputsContainer isOnlyRole={false}>
            <Text
              mt={0}
              mb={16}
              fontSize={24}
              fontFamily={font.family}
              fontWeight={700}
              color={colors.base_black}
            >
              Admin Information
            </Text>
            <Row>
              <InputAndTextContainer>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontFamily={font.family}
                  fontWeight={700}
                  color={colors.base_black}
                >
                  First name
                </Text>
                <StyledInput
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  placeholder="Enter first name here"
                />
                {formErrors.firstName && (
                  <ErrorText>{formErrors.firstName}</ErrorText>
                )}
              </InputAndTextContainer>
              <InputAndTextContainer>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontFamily={font.family}
                  fontWeight={700}
                  color={colors.base_black}
                >
                  Last name
                </Text>
                <StyledInput
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  placeholder="Enter last name here"
                />
                {formErrors.lastName && (
                  <ErrorText>{formErrors.lastName}</ErrorText>
                )}
              </InputAndTextContainer>
            </Row>
            <Row>
              <InputAndTextContainer>
                <Text
                  mt={8}
                  mb={8}
                  fontSize={18}
                  fontFamily={font.family}
                  fontWeight={700}
                  color={colors.base_black}
                >
                  Email
                </Text>
                <StyledInput
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Enter email here"
                />
                {formErrors.email && <ErrorText>{formErrors.email}</ErrorText>}
              </InputAndTextContainer>
              {fromPage !== 'adminSidebar' && (
                <InputAndTextContainer>
                  <Text
                    mt={8}
                    mb={8}
                    fontSize={18}
                    fontFamily={font.family}
                    fontWeight={700}
                    color={colors.base_black}
                  >
                    Company
                  </Text>
                  <StyledSelect
                    name="company"
                    value={formData.company}
                    onChange={handleCompanyChange}
                  >
                    <option value="">Select company</option>
                    {companies.map((company) => (
                      <option key={company.id} value={company.id}>
                        {company.name}
                      </option>
                    ))}
                  </StyledSelect>
                  {formErrors.company_id && (
                    <ErrorText>{formErrors.company_id}</ErrorText>
                  )}
                </InputAndTextContainer>
              )}
              {fromPage === 'adminSidebar' && (
                <InputAndTextContainer>
                  <Text
                    mt={8}
                    mb={8}
                    fontSize={18}
                    fontFamily={font.family}
                    fontWeight={700}
                    color={colors.base_black}
                  >
                    Phone
                  </Text>
                  <StyledInput
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    placeholder="Enter phone number"
                  />
                  {formErrors.phone && (
                    <ErrorText>{formErrors.phone}</ErrorText>
                  )}
                </InputAndTextContainer>
              )}
            </Row>
            <Row>
              <InputAndTextContainer>
                <Text
                  mt={8}
                  mb={8}
                  fontSize={18}
                  fontFamily={font.family}
                  fontWeight={700}
                  color={colors.base_black}
                >
                  Password
                </Text>
                <StyledInput
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  placeholder="Enter password here"
                />
                {formErrors.password && (
                  <ErrorText>{formErrors.password}</ErrorText>
                )}
              </InputAndTextContainer>
              <InputAndTextContainer>
                <Text
                  mt={8}
                  mb={8}
                  fontSize={18}
                  fontFamily={font.family}
                  fontWeight={700}
                  color={colors.base_black}
                >
                  Confirm Password
                </Text>
                <StyledInput
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  placeholder="Confirm password"
                />
                {formErrors.confirmPassword && (
                  <ErrorText>{formErrors.confirmPassword}</ErrorText>
                )}
              </InputAndTextContainer>
            </Row>
          </InputsContainer>
          <div style={{ marginTop: '12px' }}></div>
          <InputsContainer isOnlyRole={true}>
            <Row>
              <InputAndTextContainer>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontFamily={font.family}
                  fontWeight={700}
                  color={colors.base_black}
                >
                  Select Role Type
                </Text>
                <StyledSelect
                  name="role"
                  value={formData.role}
                  onChange={handleInputChange}
                >
                  {roles.map((role, index) => (
                    <option key={index} value={role}>
                      {role}
                    </option>
                  ))}
                </StyledSelect>
              </InputAndTextContainer>
              <InputAndTextContainer />
            </Row>
          </InputsContainer>
          <ButtonsContainer>
            <Button isBack={true} onClick={onClose}>
              Back to Dashboard
            </Button>
            <Button type="submit" isBack={false}>
              Create Admin
            </Button>
          </ButtonsContainer>
        </form>
      </ModalContent>
    </ModalBackground>
  );
};
