import styled from 'styled-components';
import { colors } from '../../../../../style-utils/colors';
import { font } from '../../../../../style-utils/text';

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: ${colors.creme_100};
  padding: 24px;
  border-radius: 8px;
  max-width: 800px;
  width: 100%;
`;

export const Button = styled.button<{ isBack: boolean }>`
  background: ${({ isBack }) =>
    isBack ? colors.primary_100 : colors.primary_500};
  border: none;
  color: ${({ isBack }) => (isBack ? 'black' : 'white')};
  padding: 16px 24px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const InputsContainer = styled.div<{ isOnlyRole: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ isOnlyRole }) => (isOnlyRole ? '16px 24px' : '24px')};
  background: white;
  border-radius: 8px;
  gap: 16px;
`;

export const Row = styled.div`
  display: flex;
  gap: 16px;
`;

export const StyledInput = styled.input`
  flex: 1;
  padding: 16px;
  border-radius: 8px;
  background: ${colors.neutral_50};
  color: ${colors.neutral_700};
  border-style: none;
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
`;

export const getDownArrowDataUrl = (color: string) => {
  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path d="M13 6L8 11L3 6" stroke="${color}" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  `;

  return `data:image/svg+xml;base64,${btoa(svg)}`;
};

export const StyledSelect = styled.select`
  flex: 1;
  padding: 16px;
  border-radius: 8px;
  background: ${colors.neutral_50};
  color: ${colors.neutral_700};
  border-style: none;
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  appearance: none; /* Remove default arrow */
  background-image: url(${getDownArrowDataUrl('black')});
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 16px;
`;

export const InputAndTextContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  gap: 16px;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const ErrorText = styled.div`
  position: absolute;
  box-sizing: border-box;
  font-family: ${font.family};
  color: ${colors.error};
  font-size: 10px;
  bottom: -16px;
  left: 16px;
`;
