import React from 'react';
import {
  Spinner,
  StyledCandidateCommentHeader,
  StyledCandidateCommentText,
  StyledCandidateCommentWrapper,
  StyledCandidateListOfCommentsWrapper,
} from '../candidate-comments.styled';
import { Text, font } from '../../../../../../../style-utils/text';
import { colors } from '../../../../../../../style-utils/colors';
import { ApplicantComment } from '../candidate-comments';

interface CandidateListOfCommentsPropsTypes {
  comments: ApplicantComment[];
  loading: boolean;
}

const CandidateListOfComments: React.FC<CandidateListOfCommentsPropsTypes> = ({
  comments,
  loading,
}) => {
  if (loading) {
    return (
      <StyledCandidateListOfCommentsWrapper>
        <Spinner />
      </StyledCandidateListOfCommentsWrapper>
    );
  }

  return (
    <StyledCandidateListOfCommentsWrapper>
      {comments.length === 0 ? (
        <Text
          fontWeight={700}
          fontSize={32}
          fontFamily={font.family}
          color={colors.neutral_700}
          margin="0"
          lineHeight="normal"
        >
          No comments
        </Text>
      ) : (
        comments.map((comment) => (
          <StyledCandidateCommentWrapper key={comment.id}>
            <StyledCandidateCommentHeader>
              <Text
                fontWeight={700}
                fontSize={16}
                fontFamily={font.family}
                color={colors.neutral_900}
                margin="0"
                lineHeight="normal"
              >
                {comment.author_name}
              </Text>
              <Text
                fontWeight={300}
                fontSize={12}
                fontFamily={font.family}
                color={colors.neutral_700}
                margin="0"
                lineHeight="normal"
              >
                {comment.createdAt.substring(0, 10)}
              </Text>
            </StyledCandidateCommentHeader>
            <StyledCandidateCommentText>
              {comment.comment}
            </StyledCandidateCommentText>
          </StyledCandidateCommentWrapper>
        ))
      )}
    </StyledCandidateListOfCommentsWrapper>
  );
};

export default CandidateListOfComments;
